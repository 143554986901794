import { useEffect, useState } from 'react';
// @mui
import { Card, Container } from '@mui/material';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { CPLIKMKTable } from '../../sections/mapping';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function CPLIKMKPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Data variable
   */
  const [data, setData] = useState([]);

  /**
   ** Handle calling API
   */
  const getData = async () => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/curriculum-indicator-course`
    );
    setData(response.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <TitlePage title="Pemetaan Cpl - Indikator - MK" />

      {isLoading ? (
        <LoadingCard isLoading={isLoading} />
      ) : (
        <Card>
          {/**
           * Data Rps Mata Kuliah
           */}
          <CPLIKMKTable data={data} />
        </Card>
      )}
    </Container>
  );
}
