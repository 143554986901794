import PropTypes from 'prop-types';
// @mui
import { Table, Typography, TableRow, TableBody, TableCell, TableContainer, Paper } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'curriculum_plo_code', label: 'Kode CPL', alignRight: false },
  { id: 'curriculum_indicator_code', label: 'Kode Indikator', alignRight: false },
  { id: 'course_plan_lo_code', label: 'Kode CPMK', alignRight: false },
];

MappingTable.prototype = {
  data: PropTypes.array.isRequired,
  groupBy: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------

export default function MappingTable({ data, groupBy }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={TABLE_HEAD} rowCount={data.length} />
          <TableBody>
            {data.map((row, index, elements) => (
              <TableRow hover key={row.course_plan_lo_code} tabIndex={-1} sx={{ borderBottom: 2 }}>
                {row.curriculum_plo_code !== elements[index - 1]?.curriculum_plo_code && (
                  <TableCell align="left" rowSpan={groupBy.cpl[row.curriculum_plo_code].length}>
                    {row.curriculum_plo_code}
                  </TableCell>
                )}

                {row.curriculum_indicator_code !== elements[index - 1]?.curriculum_indicator_code && (
                  <TableCell align="left" rowSpan={groupBy.indicator[row.curriculum_indicator_code].length}>
                    {row.curriculum_indicator_code}
                  </TableCell>
                )}

                <TableCell align="left">{row.course_plan_lo_code}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {/* jika data tidak ada */}
          {!data.length && (
            <TableBody>
              <TableRow sx={{ borderBottom: 2 }}>
                <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Data tidak ditemukan
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
