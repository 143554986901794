import { useEffect, useState } from 'react';
// @mui
import { Card, Container } from '@mui/material';
// components
import TitlePage from '../../components/title-page/TitlePage';
import { LoadingCard } from '../../components/loading';
// sections
import { MataKuliahTable } from '../../sections/mata-kuliah';
import ModalAction from './ModalAction';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function MonevMKPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [openAction, setOpenAction] = useState(null);

  /**
   ** Data variable
   */
  const [data, setData] = useState({
    coursePlan: [],
    periode: [],
  });
  const [row, setRow] = useState(null);
  const [periode, setPeriode] = useState('');

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenAction = (event, row) => {
    setRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setOpenAction(null);
    setRow(null);
  };

  /**
   ** Handle data
   */
  const changePeriode = (event) => {
    setPeriode(event.target.value);
  };

  /**
   ** Handle calling API
   */
  const getPeriode = async () => {
    const response = await GatewayHttpService.secureGet(`${process.env.REACT_APP_BACKEND_HOST}/api/v2/periode`);

    const lastPeriode = response.data.data[0];
    setPeriode(`${lastPeriode.semester_type}-${lastPeriode.year}`);
    setData((data) => ({ ...data, periode: response.data.data }));
    setIsLoading(false);
  };

  const getCoursePlan = async (periode) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan?semesterType=${periode.semester_type}&year=${periode.year}`
    );
    setData((data) => ({ ...data, coursePlan: response.data.data }));
  };

  useEffect(() => {
    getPeriode();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Monitoring Perkuliahan" />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card>
            {/**
             * Data Rps Mata Kuliah
             */}
            <MataKuliahTable
              data={data}
              periode={periode}
              onLoadData={getCoursePlan}
              onChangePeriode={changePeriode}
              onOpenAction={handleOpenAction}
            />
          </Card>
        )}
      </Container>

      {/**
       * Popup Aksi
       */}
      <ModalAction row={row} open={openAction} onClose={handleCloseAction} />
    </>
  );
}
