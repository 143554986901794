import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container } from '@mui/material';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { RekapNilaiTable } from '../../sections/rekap-nilai';
import Menu from './Menu';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function RekapNilaiPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({
    class: {},
    student: {},
  });
  const [kelas, setKelas] = useState(0);

  /**
   ** Handle data
   */
  const changeKelas = (event) => {
    setKelas(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getClass = async () => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/class?indicator=true`
    );
    setData((data) => ({ ...data, class: response.data.data }));
    setIsLoading(false);
  };

  const getStudent = async (indexKelas = 0) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[indexKelas].id}/monev`
    );
    setData((data) => ({ ...data, student: response.data.data }));
  };

  useEffect(() => {
    getClass();
  }, []);

  return (
    <Container>
      <TitlePage title="Rekap Nilai" />

      <Menu />

      {isLoading ? (
        <LoadingCard isLoading={isLoading} />
      ) : (
        <Card>
          {/**
           * Data Mahasiswa dan Nilai
           */}
          <RekapNilaiTable data={data} kelas={kelas} onChangeKelas={changeKelas} onLoadData={getStudent} />
        </Card>
      )}
    </Container>
  );
}
