// react
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Popover, MenuItem } from '@mui/material';
// components
import Iconify from '../../components/iconify';

ModalAction.prototype = {
  row: PropTypes.object.isRequired,
  open: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function ModalAction({ row, open, onClose }) {
  /**
   ** Component variable
   */
  const navigate = useNavigate();

  /**
   ** Data variable
   */
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem onClick={() => navigate(`/mata-kuliah/${row.id}`)}>
        <Iconify icon={'mdi:paper'} sx={{ mr: 2 }} />
        RPS
      </MenuItem>

      {permissions.some((permission) => ['bap_access', 'bap_manage'].includes(permission)) && (
        <MenuItem onClick={() => navigate(`/mata-kuliah/${row.id}/bap`)}>
          <Iconify icon={'mdi:newspaper-variant-outline'} sx={{ mr: 2 }} />
          BAP
        </MenuItem>
      )}

      {permissions.some((permission) => ['classes_access', 'classes_manage'].includes(permission)) && (
        <>
          <MenuItem onClick={() => navigate(`/mata-kuliah/${row.id}/nilai`)}>
            <Iconify icon={'ic:outline-assessment'} sx={{ mr: 2 }} />
            Nilai
          </MenuItem>
          <MenuItem onClick={() => navigate(`/mata-kuliah/${row.id}/rekap-nilai`)}>
            <Iconify icon={'material-symbols:monitoring-rounded'} sx={{ mr: 2 }} />
            Rekap Nilai
          </MenuItem>
        </>
      )}
    </Popover>
  );
}
