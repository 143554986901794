import { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Table, Typography, TableRow, TableBody, TableCell, TableContainer, Paper } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

AssessmentDetailTable.prototype = {
  coursePlanAssessments: PropTypes.array.isRequired,
  coursePlanLos: PropTypes.array.isRequired,
};

// ----------------------------------------------------------------------

export default function AssessmentDetailTable({ coursePlanAssessments, coursePlanLos }) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);

  /**
   ** Data variable
   */
  const [sumColumn, setSumColumn] = useState([]);
  const [sumTotal, setSumTotal] = useState(0);

  /**
   ** Handle component
   */
  const modifyHeader = () => {
    const modifiedHeaderTable = [{ id: 'cpmk', label: 'CPMK/Komponen Penilaian', alignRight: false }];

    coursePlanAssessments.forEach((assessment) =>
      modifiedHeaderTable.push({
        id: assessment.name.toLowerCase(),
        label: assessment.name,
        alignRight: false,
      })
    );

    modifiedHeaderTable.push({ id: 'total', label: 'Total', alignRight: false });

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const filterBobot = (cpa, assessmentDetails) => {
    const result = assessmentDetails.filter((detail) => cpa.id === detail.course_plan_assessment_id);
    return result.length ? `${result[0].percentage}%` : '';
  };

  const sumRowBobotCpmk = (los) => {
    let total = 0;

    los.forEach((lo) => {
      const sum = lo.assessment_details.reduce((sum, object) => sum + parseFloat(object.percentage), 0);
      lo.total = sum;
      total += sum;
    });

    setSumTotal(total);
  };

  const sumColumnBobotCpmk = (cpas, los) => {
    const sumCol = Array(cpas.length).fill(0);

    los.forEach((lo) => {
      cpas.forEach((cpa, index) => {
        const result = lo.assessment_details.filter((detail) => cpa.id === detail.course_plan_assessment_id);

        if (result.length) {
          sumCol[index] += parseFloat(result[0].percentage);
        }
      });
    });

    setSumColumn(sumCol);
  };

  useLayoutEffect(() => {
    modifyHeader();
    sumRowBobotCpmk(coursePlanLos);
    sumColumnBobotCpmk(coursePlanAssessments, coursePlanLos);
  }, []);

  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={tableHead} rowCount={coursePlanLos.length} />

          <TableBody>
            {coursePlanLos.map((row) => (
              <TableRow hover key={row.id} tabIndex={-1} sx={{ borderBottom: 2 }}>
                <TableCell align="left">{`(${row.code}) ${row.name}`}</TableCell>

                {coursePlanAssessments.map((cpa) => (
                  <TableCell key={cpa.id} align="left">
                    {filterBobot(cpa, row.assessment_details)}
                  </TableCell>
                ))}

                <TableCell sx={{ fontWeight: 'bold' }} align="left">
                  {row.total}%
                </TableCell>
              </TableRow>
            ))}

            {coursePlanLos.length && (
              <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Total
                </TableCell>
                {sumColumn.map((sum, index) => (
                  <TableCell key={index} align="left" sx={{ fontWeight: 'bold' }}>
                    {sum}%
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: 'bold' }}>{sumTotal}%</TableCell>
              </TableRow>
            )}
          </TableBody>

          {/* jika data tidak ada */}
          {!coursePlanLos.length && (
            <TableBody>
              <TableRow sx={{ borderBottom: 2 }}>
                <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Data tidak ditemukan
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
