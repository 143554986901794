import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
// @mui
import {
  Typography,
  Button,
  InputLabel,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import * as yup from 'yup';
// utils
import { gender, maritalStatus, religion } from '../../utils/constant';

// ----------------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

ModalUpdateProfil.prototype = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function ModalUpdateProfil({ user, open, onClose, onUpdateData }) {
  /**
   ** Component variable
   */
  const [isSubmit, setIsSubmit] = useState(false);

  /**
   ** Data variable
   */
  const [fileName, setFileName] = useState('');

  /**
   ** Handle data
   */
  const handleUpdateData = async () => {
    setIsSubmit(true);
    await onUpdateData(values);
    onClose();

    setValues({
      photo: null,
      email: '',
      nik: '',
      nidn: '',
      karpeg: '',
      birthday: '',
      birthplace: '',
      phone: '',
      address: '',
      gender: '',
      marital_status: '',
      religion: '',
    });
    setErrors({});
    setTouched({
      photo: false,
      email: false,
      nik: false,
      nidn: false,
      karpeg: false,
      birthday: false,
      birthplace: false,
      phone: false,
      address: false,
      gender: false,
      marital_status: false,
      religion: false,
    });
    setIsSubmit(false);
  };

  useEffect(() => {
    if (open) {
      setValues({
        email: user.email,
        nik: user.nik,
        nidn: user?.nidn ?? '',
        karpeg: user?.karpeg ?? '',
        birthday: dayjs(user?.birthday) ?? '',
        birthplace: user?.birthplace ?? '',
        phone: user?.phone ? user?.phone.match(/[^+62|0]\d+/g)[0] : '',
        address: user?.address ?? '',
        gender: user?.gender ?? '',
        marital_status: user?.marital_status ?? '',
        religion: user?.religion ?? '',
      });
    }
  }, [open]);

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        photo: null,
        email: '',
        nik: '',
        nidn: '',
        karpeg: '',
        birthday: '',
        birthplace: '',
        phone: '',
        address: '',
        gender: '',
        marital_status: '',
        religion: '',
      },
      validationSchema: yup.object().shape({
        photo: yup
          .mixed()
          .test('fileFormat', 'Hanya mengizinkan file gambar (png,jpg,jpeg,bpm, dan webp)', (value) => {
            if (value) {
              const supportedFormats = ['image/png', 'image/jpeg', 'image/bmp', 'image/webp'];
              return supportedFormats.includes(value?.type);
            }

            return true;
          })
          .test('fileSize', 'Ukuran maksimal hanya 2MB', (value) => {
            if (value) {
              return value.size <= 1024 * 1024;
            }

            return true;
          })
          .nullable(),
        email: yup.string().required('Email tidak boleh kosong').email('Email tidak valid'),
        nik: yup.string().required('Nik tidak boleh kosong').length(16, 'Nik harus 16 karakter'),
        nidn: yup.string().nullable().min(3, 'Nidn minimal 3 karakter'),
        karpeg: yup.string().nullable().min(3, 'Karpeg minimal 3 karakter'),
        birthplace: yup.string().nullable(),
        birthday: yup.mixed().when('birthplace', {
          is: (value) => !!value,
          then: () =>
            yup
              .date()
              .typeError('Tanggal tidak valid')
              .required('Tanggal lahir tidak boleh kosong')
              .max(dayjs(), 'Tanggal tidak boleh melewati hari ini'),
          otherwise: (schema) => schema.nullable(),
        }),
        phone: yup.string().nullable().min(10, 'No. hp minimal 10 karakter'),
        address: yup.string().nullable(),
        gender: yup.string().nullable(),
        marital_status: yup.number().nullable(),
        religion: yup.number().nullable(),
      }),
      onSubmit: handleUpdateData,
    });

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={onClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Edit Data'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <InputLabel sx={{ color: '#000' }} htmlFor={'photo-box'}>
            {'Foto profil'}
          </InputLabel>
          <TextField
            margin="dense"
            id="photo-box"
            name="photo-box"
            label="Foto profil"
            fullWidth
            value={fileName}
            error={Boolean(errors.photo) && Boolean(touched.photo)}
            helperText={Boolean(errors.photo) && Boolean(touched.photo) ? errors.photo : ''}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <Button component="label" variant="outlined" sx={{ minWidth: 120, marginRight: 1 }}>
                  Unggah Foto
                  <VisuallyHiddenInput
                    id="photo"
                    name="photo"
                    type="file"
                    onChange={(event) => {
                      setFileName(event.target.files[0]?.name ?? '');
                      setFieldValue('photo', event.target.files[0]);
                    }}
                  />
                </Button>
              ),
            }}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'email'}>
            {'Email '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            type="email"
            margin="dense"
            id="email"
            name="email"
            label="Email"
            fullWidth
            value={values.email}
            onChange={handleChange}
            error={Boolean(errors.email) && Boolean(touched.email)}
            helperText={Boolean(errors.email) && Boolean(touched.email) ? errors.email : ''}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'nik'}>
            {'Nik '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            margin="dense"
            id="nik"
            name="nik"
            label="Nik"
            type="text"
            fullWidth
            value={values.nik}
            error={Boolean(errors.nik) && Boolean(touched.nik)}
            helperText={Boolean(errors.nik) && Boolean(touched.nik) ? errors.nik : ''}
            onChange={(event) => {
              const value = event.target.value.match(/\d{0,16}/g);
              setFieldValue('nik', value ? value[0] : '');
            }}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'nidn'}>
            {'Nidn'}
          </InputLabel>
          <TextField
            margin="dense"
            id="nidn"
            name="nidn"
            label="Nidn"
            type="text"
            fullWidth
            value={values.nidn}
            error={Boolean(errors.nidn) && Boolean(touched.nidn)}
            helperText={Boolean(errors.nidn) && Boolean(touched.nidn) ? errors.nidn : ''}
            onChange={(event) => {
              const value = event.target.value.match(/\d+/g);
              setFieldValue('nidn', value ? value[0] : '');
            }}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'karpeg'}>
            {'Karpeg'}
          </InputLabel>
          <TextField
            margin="dense"
            id="karpeg"
            name="karpeg"
            label="Karpeg"
            type="text"
            fullWidth
            value={values.karpeg}
            error={Boolean(errors.karpeg) && Boolean(touched.karpeg)}
            helperText={Boolean(errors.karpeg) && Boolean(touched.karpeg) ? errors.karpeg : ''}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'birthplace'}>
            {'Tempat lahir'}
          </InputLabel>
          <TextField
            margin="dense"
            id="birthplace"
            name="birthplace"
            label="Tempat lahir"
            type="text"
            fullWidth
            value={values.birthplace}
            error={Boolean(errors.birthplace) && Boolean(touched.birthplace)}
            helperText={Boolean(errors.birthplace) && Boolean(touched.birthplace) ? errors.birthplace : ''}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }}>{'Tanggal lahir'}</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="birthday"
              name="birthday"
              label="Tanggal lahir"
              disabled={!values.birthplace}
              value={values.birthday}
              onChange={(value) => setFieldValue('birthday', value)}
              slotProps={{
                textField: {
                  error: Boolean(errors.birthday),
                  helperText: errors.birthday ?? '',
                },
              }}
              disableFuture
              format="DD/MM/YYYY"
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
            />
          </LocalizationProvider>

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'phone'}>
            {'No. hp'}
          </InputLabel>
          <TextField
            margin="dense"
            id="phone"
            name="phone"
            label="No.hp"
            type="tel"
            fullWidth
            value={values.phone}
            error={Boolean(errors.phone) && Boolean(touched.phone)}
            helperText={Boolean(errors.phone) && Boolean(touched.phone) ? errors.phone : ''}
            onChange={(event) => {
              const value = event.target.value.match(/[8]{1}[0-9]{0,11}/g);
              setFieldValue('phone', value ? value[0] : '');
            }}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'address'}>
            {'Alamat'}
          </InputLabel>
          <TextField
            margin="dense"
            id="address"
            name="address"
            label="Alamat"
            type="text"
            fullWidth
            value={values.address}
            error={Boolean(errors.address) && Boolean(touched.address)}
            helperText={Boolean(errors.address) && Boolean(touched.address) ? errors.address : ''}
            onChange={handleChange}
          />

          <Typography mt={3}>{'Jenis kelamin'}</Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="gender">Jenis kelamin</InputLabel>
            <Select
              labelId="gender"
              id="gender"
              name="gender"
              value={values.gender}
              error={Boolean(errors.gender) && Boolean(touched.gender)}
              onChange={handleChange}
              label="Jenis Kelamin"
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {Object.entries(gender).map((row) => (
                <MenuItem key={row[0]} value={row[0]}>
                  {row[1]}
                </MenuItem>
              ))}
            </Select>
            {Boolean(errors.gender) && Boolean(touched.gender) && (
              <FormHelperText error>{errors.gender}</FormHelperText>
            )}
          </FormControl>

          <Typography mt={3}>{'Status perkawinan'}</Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="marital_status">Status perkawinan</InputLabel>
            <Select
              labelId="marital_status"
              id="marital_status"
              name="marital_status"
              value={values.marital_status}
              error={Boolean(errors.marital_status) && Boolean(touched.marital_status)}
              onChange={handleChange}
              label="Status perkawinan"
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {Object.entries(maritalStatus).map((row) => (
                <MenuItem key={row[0]} value={row[0]}>
                  {row[1]}
                </MenuItem>
              ))}
            </Select>
            {Boolean(errors.marital_status) && Boolean(touched.marital_status) && (
              <FormHelperText error>{errors.marital_status}</FormHelperText>
            )}
          </FormControl>

          <Typography mt={3}>{'Agama'}</Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="religion">Agama</InputLabel>
            <Select
              labelId="religion"
              id="religion"
              name="religion"
              value={values.religion}
              error={Boolean(errors.religion) && Boolean(touched.religion)}
              onChange={handleChange}
              label="Agama"
            >
              <MenuItem value={''}>
                <em>None</em>
              </MenuItem>
              {Object.entries(religion).map((row) => (
                <MenuItem key={row[0]} value={row[0]}>
                  {row[1]}
                </MenuItem>
              ))}
            </Select>
            {Boolean(errors.religion) && Boolean(touched.religion) && (
              <FormHelperText error>{errors.religion}</FormHelperText>
            )}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
