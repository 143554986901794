import PropTypes from 'prop-types';
// @mui
import { Paper, TableRow, TableBody, TableCell } from '@mui/material';
import { PulseLoader } from 'react-spinners';

LoadingTable.prototype = {
  headLength: PropTypes.number,
  isLoading: PropTypes.bool,
};

function LoadingTable({ headLength, isLoading }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={headLength} sx={{ py: 5 }}>
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <PulseLoader color={'#308fe8'} size={10} loading={isLoading} />
          </Paper>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default LoadingTable;
