import Proptypes from 'prop-types';
// @mui
import {
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';

ModalInsert.prototype = {
  data: Proptypes.object.isRequired,
  open: Proptypes.bool.isRequired,
  isSubmit: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  onInsertData: Proptypes.func.isRequired,
};

export default function ModalInsert({ data, open, isSubmit, onClose, onInsertData }) {
  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setErrors({});
    setTouched({ curriculum_plo_id: false, code: false, indicator: false, min_grade: false });
  };

  /**
   ** Handle data
   */
  const handleInsertData = async () => {
    await onInsertData(values);
    setValues({ curriculum_plo_id: '', code: '', indicator: '', min_grade: 60 });
    setErrors({});
    setTouched({ curriculum_plo_id: false, code: false, indicator: false, min_grade: false });
  };

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        curriculum_plo_id: '',
        code: '',
        indicator: '',
        min_grade: 60,
      },
      validationSchema: yup.object().shape({
        curriculum_plo_id: yup.number().required('Capaian lulusan tidak boleh kosong'),
        code: yup.string().required('Kode tidak boleh kosong'),
        indicator: yup.string().required('Indikator tidak boleh kosong'),
        min_grade: yup
          .number()
          .required('Nilai minimal tidak boleh kosong')
          .min(0, 'Nilai minimal tidak boleh kurang dari 0')
          .max(100, 'Nilai minimal tidak boleh lebih dari 100'),
      }),
      onSubmit: handleInsertData,
    });

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Tambah Data'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography sx={{ color: '#000' }}>
            {'Capaian lulusan (CPL) '}
            <span style={{ color: '#FF4842' }}>*</span>
          </Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="curriculum_plo_id">Capaian lulusan</InputLabel>
            <Select
              labelId="curriculum_plo_id"
              id="curriculum_plo_id"
              name="curriculum_plo_id"
              value={values.curriculum_plo_id}
              error={Boolean(errors.curriculum_plo_id) && Boolean(touched.curriculum_plo_id)}
              onChange={handleChange}
              label="Capaian lulusan"
            >
              {data.plo.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.code}
                </MenuItem>
              ))}
            </Select>
            {Boolean(errors.curriculum_plo_id) && Boolean(touched.curriculum_plo_id) && (
              <FormHelperText error>{errors.curriculum_plo_id}</FormHelperText>
            )}
          </FormControl>

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'code'}>
            {'Kode '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            margin="dense"
            id="code"
            name="code"
            value={values.code}
            label="Kode"
            type="text"
            fullWidth
            error={Boolean(errors.code) && Boolean(touched.code)}
            helperText={Boolean(errors.code) && Boolean(touched.code) ? errors.code : ''}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'indicator'}>
            {'Indikator '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="indicator"
            name="indicator"
            value={values.indicator}
            label="Indikator"
            type="text"
            fullWidth
            rows={4}
            error={Boolean(errors.indicator) && Boolean(touched.indicator)}
            helperText={Boolean(errors.indicator) && Boolean(touched.indicator) ? errors.indicator : ''}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'min_grade'}>
            {'Nilai minimal '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            margin="dense"
            id="min_grade"
            name="min_grade"
            label="Nilai minimal"
            type="text"
            value={values.min_grade}
            fullWidth
            error={Boolean(errors.min_grade) && Boolean(touched.min_grade)}
            helperText={Boolean(errors.min_grade) && Boolean(touched.min_grade) ? errors.min_grade : ''}
            onChange={(event) => {
              const value = event.target.value.match(/\d+/g);
              setFieldValue('min_grade', value ? value[0] : '');
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
