import PropTypes from 'prop-types';
// @mui
import { Table, TableContainer, TableCell, TableRow, TableBody } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'participant', label: 'Peserta', alignRight: false },
  { id: 'percentage', label: 'Persentase Kehadiran', alignRight: false },
];

AttendancePercentageTable.prototype = {
  lecturer: PropTypes.number.isRequired,
  student: PropTypes.number.isRequired,
};

// ----------------------------------------------------------------------
export default function AttendancePercentageTable({ lecturer, student }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={TABLE_HEAD} />
          <TableBody>
            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Dosen</TableCell>
              <TableCell>{lecturer}%</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Mahasiswa</TableCell>
              <TableCell>{student}%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
