import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  Typography,
  TableContainer,
  Paper,
} from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead, TableListToolbar } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'curriculum_plo_code', label: 'Kode CPL', alignRight: false },
  { id: 'curriculum_indicator_code', label: 'Kode Indikator', alignRight: false },
  { id: 'course_names', label: 'Mata Kuliah', alignRight: false },
];

CPLIKMKTable.prototype = {
  data: PropTypes.array.isRequired,
};

// ----------------------------------------------------------------------

export default function CPLIKMKTable({ data }) {
  /**
   ** Component variable
   */
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('curriculum_plo_code');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName, 'curriculum_plo_code');
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <TableListToolbar filterData={filterName} onFilterData={handleFilterByName} placeholder={'Kode CPL...'} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow hover key={index} tabIndex={-1}>
                  <TableCell align="left" scope="row">
                    {row.curriculum_plo_code}
                  </TableCell>
                  <TableCell align="left">{row.curriculum_indicator_code}</TableCell>
                  <TableCell align="left">
                    {row.course_names.map((item, index, elements) => {
                      const comma = index !== elements.length - 1 ? ', ' : '';
                      return `${item}${comma}`;
                    })}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={TABLE_HEAD.length} />
                </TableRow>
              )}
            </TableBody>

            {/* jika data tidak ada */}
            {!data.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Data tidak ditemukan
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            {/* jika hasil pencarian tidak ditemukan */}
            {isNotFound && data.length > 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Data tidak ditemukan
                      </Typography>

                      <Typography variant="body2">
                        Tidak ditemukan hasil pencarian &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Periksa kembali kata kunci yang anda masukkan.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
