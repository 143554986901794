import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container } from '@mui/material';
// components
import { enqueueSnackbar } from 'notistack';
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { NilaiTable } from '../../sections/nilai';
import Menu from './Menu';
import ModalAction from './ModalAction';
import ModalInput from './ModalInput';
import ModalUpload from './ModalUpload';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function NilaiPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openAction, setOpenAction] = useState(null);
  const [reload, setReload] = useState(0);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({
    class: {},
    student: {},
  });
  const [row, setRow] = useState(null);
  const [kelas, setKelas] = useState(0);
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenAction = (event, row) => {
    setRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setOpenAction(null);
    setRow(null);
  };

  const handleOpenInput = () => {
    setOpenInput(true);
    setOpenAction(null);
  };

  const handleCloseInput = () => {
    setOpenInput(false);
    setRow(null);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  /**
   ** Handle data
   */
  const changeKelas = (event) => {
    setKelas(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getClass = async () => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/class?cpmk=true&assessment=true`
    );
    setData((data) => ({ ...data, class: response.data.data }));
    setIsLoading(false);
  };

  const getStudent = async (indexKelas = 0) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[indexKelas].id}/student?assessment=true`
    );
    setData((data) => ({ ...data, student: response.data.data }));
  };

  const inputAssessment = async (values) => {
    setIsSubmit(true);

    const newData = {
      study_plan_detail_id: row.study_plan_detail_id,
      assessments: values.course_plan_los.map((item) => ({
        course_plan_assessment_id: item.course_plan_assessment_id,
        course_plan_lo_id: item.course_plan_lo_id,
        grade: item.grade,
      })),
    };

    const response = await GatewayHttpService.securePost(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/assessment`,
      newData
    );

    handleCloseInput();
    setIsSubmit(false);

    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setReload((reload) => reload + 1);
    } else if (response.status === 409) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const downloadTemplate = async () => {
    setIsDownload(true);
    const response = await GatewayHttpService.secureGetFile(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/assessment/template`
    );

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = `Template Nilai ${data.class.name} - ${data.class.class_courses[kelas].name}.xlsx`;
    setIsDownload(false);
    link.click();
  };

  const inputBulkAssessment = async (fileData) => {
    setIsSubmit(true);
    const promise = GatewayHttpService.securePost(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/assessment/bulk`,
      fileData
    );
    handleCloseUpload();
    setIsSubmit(false);
    enqueueSnackbar('Data sedang diproses', {
      variant: 'info',
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
    const response = await promise;
    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setReload((reload) => reload + 1);
    } else if (response.status === 400 || response.status === 409) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  useEffect(() => {
    getClass();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Nilai" />

        <Menu data={data} isDownload={isDownload} onDownload={downloadTemplate} onOpenUpload={handleOpenUpload} />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card>
            <NilaiTable
              data={data}
              kelas={kelas}
              reload={reload}
              canModify={permissions.some((permission) => permission === 'assessments_manage')}
              onLoadData={getStudent}
              onChangeKelas={changeKelas}
              onOpenAction={handleOpenAction}
            />
          </Card>
        )}
      </Container>

      {/**
       * Popup Aksi
       */}
      <ModalAction open={openAction} onClose={handleCloseAction} onOpenInput={handleOpenInput} />

      {/**
       * Popup Input Nilai
       */}
      <ModalInput
        data={data}
        row={row}
        open={openInput}
        isSubmit={isSubmit}
        onClose={handleCloseInput}
        onInputData={inputAssessment}
      />

      {/**
       * Popup Upload Excel
       */}
      <ModalUpload
        open={openUpload}
        isSubmit={isSubmit}
        onClose={handleCloseUpload}
        onUploadData={inputBulkAssessment}
      />
    </>
  );
}
