import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { CloudUpload } from '@mui/icons-material';
// xlsx
import * as xlsx from 'xlsx';

// ----------------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

ModalUpload.prototype = {
  open: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadData: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function ModalUpload({ open, isSubmit, onClose, onUploadData }) {
  /**
   ** Data variable
   */
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [errorFile, setErrorFile] = useState(null);

  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setFile(null);
    setFileName(null);
    setErrorFile(null);
  };

  /**
   ** Handle data
   */
  const handleUploadFile = (event) => {
    const fileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];
    const selectedFile = event.target.files[0];

    // get filename
    if (selectedFile) {
      setFileName(event.target.files[0].name);
    } else {
      setFileName(null);
    }

    // read buffer file
    if (selectedFile && fileTypes.includes(selectedFile.type)) {
      setErrorFile(null);

      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (event) => {
        setFile(event.target.result);
      };
    } else {
      setErrorFile('Hanya mengizinkan file excel!');
      setFile(null);
    }
  };

  const getStudentData = (worksheet) => {
    /**
     * ADD STUDENT
     */
    let row = 8;
    let student = {};
    const students = [];

    while (
      Object.hasOwn(worksheet, `B${row}`) &&
      Object.hasOwn(worksheet, `C${row}`) &&
      Object.hasOwn(worksheet, `D${row}`)
    ) {
      student = {
        id: worksheet[`B${row}`].v,
        nim: worksheet[`C${row}`].v,
        name: worksheet[`D${row}`].v,
        grades: [],
      };

      //   student.grades.push(...getGradeFromCell(worksheet, result.assessments.length, row));

      students.push(student);
      row += 1;
    }

    return students;
  };

  const getStudentGrade = (worksheet, column, students) => {
    let row = 8;

    students.map((student) => {
      student.grades.push(worksheet[`${column}${row}`]?.v ?? 0);
      row += 1;
      return student;
    });

    return students;
  };

  const getAssessmentData = (worksheet, asciiCode, prefix, mergeCell, firstMerge, lastMerge, students) => {
    const results = [];
    let startMerge = '';
    let endMerge = '';
    let startMergeChar = '';
    let endMergeChar = '';
    // console.log(`start with prefix : '${prefix}'`);

    // asciiCode 90 = Z
    while (asciiCode <= 90) {
      // jika kolom tidak ada nilainya
      if (
        (!worksheet[`${prefix}${String.fromCharCode(asciiCode)}5`]?.v && firstMerge === '') ||
        !worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`]?.v ||
        !worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`]?.v
      ) {
        break;
      }

      const cell = `${prefix}${String.fromCharCode(asciiCode)}5`;
      // eslint-disable-next-line no-loop-func
      const isMerge = mergeCell.filter((merge) => merge[0] === cell || merge[0] === firstMerge);

      if (isMerge.length) {
        startMerge = firstMerge !== '' ? firstMerge : isMerge[0][0];
        endMerge = lastMerge !== '' ? lastMerge : isMerge[0][1];
        startMergeChar = startMerge.match(/[A-Z]+/g)[0];
        endMergeChar = endMerge.match(/[A-Z]+/g)[0];
        // console.log(`merge : ${startMerge} - ${endMerge}`);

        // get data from column
        while (
          asciiCode <= endMergeChar.charCodeAt(endMergeChar.length - 1) &&
          endMergeChar.length === startMergeChar.length
        ) {
          // console.log(`block while 1 : ${prefix}${String.fromCharCode(asciiCode)} - ${asciiCode}`);

          if (
            worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`]?.v &&
            worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`]?.v
          ) {
            results.push({
              course_plan_assessment_name: worksheet[startMerge].v,
              course_plan_lo_code: worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`].v,
              percentage: worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`].v,
            });

            students = getStudentGrade(worksheet, `${prefix}${String.fromCharCode(asciiCode)}`, students);

            asciiCode += 1;
          }
        }

        // jika merge sampai next prefix
        while (asciiCode <= 90 && endMergeChar.length === startMergeChar.length + 1) {
          // console.log(`block while 2 : ${prefix}${String.fromCharCode(asciiCode)} - ${asciiCode}`);

          if (
            worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`]?.v &&
            worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`]?.v
          ) {
            results.push({
              course_plan_assessment_name: worksheet[startMerge].v,
              course_plan_lo_code: worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`].v,
              percentage: worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`].v,
            });

            students = getStudentGrade(worksheet, `${prefix}${String.fromCharCode(asciiCode)}`, students);

            asciiCode += 1;
          }

          if (
            `${prefix}${String.fromCharCode(asciiCode)}` > endMergeChar &&
            endMergeChar.length === `${prefix}${String.fromCharCode(asciiCode)}`.length
          ) {
            break;
          }
        }

        firstMerge = '';
        lastMerge = '';
      } else {
        // console.log(`block else : ${prefix}${String.fromCharCode(asciiCode)} - ${asciiCode}`);

        results.push({
          course_plan_assessment_name: worksheet[cell].v,
          course_plan_lo_code: worksheet[`${prefix}${String.fromCharCode(asciiCode)}6`].v,
          percentage: worksheet[`${prefix}${String.fromCharCode(asciiCode)}7`].v,
        });

        asciiCode += 1;
        startMerge = '';
        endMerge = '';
      }
    }

    return { firstMerge: startMerge, lastMerge: endMerge, assessments: results, students };
  };

  const getAssessment = (worksheet, students) => {
    /**
     * ADD ASSESSMENT
     */
    let asciiCode = 69; // Huruf E
    let prefix = '';

    const mergeCell = worksheet['!merges'].map((merge) => xlsx.utils.encode_range(merge).split(':'));
    const lastColumn = worksheet['!ref'].split(':')[1].match(/[A-Z]+/g)[0];
    let startMerge = '';
    let endMerge = '';
    const assessments = [];
    let resultStudents = [];

    do {
      const {
        firstMerge,
        lastMerge,
        assessments: results,
        students: studentResults,
      } = getAssessmentData(worksheet, asciiCode, prefix, mergeCell, startMerge, endMerge, students);
      startMerge = firstMerge;
      endMerge = lastMerge;
      assessments.push(...results);
      resultStudents = studentResults;

      // Kembalikan asciiCode ke huruf A
      asciiCode = 65;
      prefix += 'A';
    } while (prefix.length + 1 <= lastColumn.length);

    return { assessments, students: resultStudents };
  };

  const handleModifyFileData = () => {
    const workbook = xlsx.read(file, { type: 'buffer' });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    const result = {
      course_name: worksheet.C1?.v,
      lecturer_name: worksheet.C2?.v,
      class_name: worksheet.C3?.v,
      assessments: [],
      students: [],
    };

    result.students.push(...getStudentData(worksheet));
    const { assessments, students } = getAssessment(worksheet, result.students);
    result.assessments = assessments;
    result.students = students;

    return result;
  };

  const handleUploadData = async (event) => {
    event.preventDefault();

    const fileData = handleModifyFileData();
    await onUploadData(fileData);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleUploadData}>
        <DialogTitle>{'Unggah Nilai'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ minWidth: 320, marginY: 5, textAlign: 'center' }}>
          <Button component="label" variant="contained" sx={{ marginBottom: 1 }} startIcon={<CloudUpload />}>
            Unggah berkas
            <VisuallyHiddenInput id="file" name="file" type="file" onChange={handleUploadFile} />
          </Button>

          {fileName ? (
            <Typography>{fileName}</Typography>
          ) : (
            <Typography sx={{ fontStyle: 'italic' }}>{'Tidak ada file yang diunggah'}</Typography>
          )}

          {errorFile && <Typography sx={{ color: 'error.main' }}>{errorFile}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={!file || isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
