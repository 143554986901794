import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Button } from '@mui/material';
import { ClockLoader } from 'react-spinners';
// components
import Iconify from '../../components/iconify';

Menu.prototype = {
  isPrint: PropTypes.bool,
  onPrint: PropTypes.func,
  printProps: PropTypes.any,
};

export default function Menu({ isPrint, onPrint, printProps }) {
  /**
   ** Component variable
   */
  const navigate = useNavigate();

  /**
   ** Data variable
   */

  return (
    <Card sx={{ padding: 3, marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:arrow-circle-left-fill" />}
          onClick={() => navigate('/beranda')}
        >
          Kembali
        </Button>

        <Button
          variant="contained"
          startIcon={
            isPrint ? (
              <ClockLoader color={'#308fe8'} size={18} loading={isPrint} />
            ) : (
              <Iconify icon="eva:printer-outline" />
            )
          }
          onClick={onPrint}
          sx={{ marginRight: 1 }}
          {...printProps}
        >
          Cetak
        </Button>
      </Stack>
    </Card>
  );
}
