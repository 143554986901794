import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Stack, Button } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { ItemDataVertical } from '../../components/item-data';
// utils
import { gender, maritalStatus, religion } from '../../utils/constant';

CardBio.prototype = {
  user: PropTypes.object.isRequired,
  onOpenUpdate: PropTypes.func.isRequired,
};

export default function CardBio({ user, onOpenUpdate }) {
  return (
    <Card sx={{ padding: 3, marginTop: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={5}>
        <Typography variant="h6">Profil</Typography>

        <Button
          variant="contained"
          sx={{ marginTop: 3 }}
          startIcon={<Iconify icon="eva:edit-fill" />}
          onClick={onOpenUpdate}
        >
          Edit
        </Button>
      </Stack>

      <ItemDataVertical itemLabel="Nama" itemValue={user.name} />
      <ItemDataVertical itemLabel="Email" itemValue={user.email} />
      <ItemDataVertical itemLabel="Nim" itemValue={user.nik} />
      <ItemDataVertical itemLabel="Nip" itemValue={user.nip} />
      <ItemDataVertical itemLabel="Nidn" itemValue={user.nidn} />
      <ItemDataVertical itemLabel="Kartu Kepegawaian" itemValue={user.karpeg} />
      <ItemDataVertical itemLabel="Npwp" itemValue={user.npwp} />
      <ItemDataVertical itemLabel="Jenis Kelamin" itemValue={gender[user.gender]} />
      <ItemDataVertical
        itemLabel=" Tempat / tanggal Lahir"
        itemValue={
          user.birthday &&
          user.birthplace &&
          `${user.birthplace}, ${new Date(user.birthday).toLocaleDateString('id-ID', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}`
        }
      />
      <ItemDataVertical itemLabel="No. Telpon" itemValue={user.phone} />
      <ItemDataVertical itemLabel="Alamat" itemValue={user.address} />
      <ItemDataVertical itemLabel="Departemen" itemValue={user.department_name} />
      <ItemDataVertical itemLabel="Status Perkawinan" itemValue={maritalStatus[user.marital_status]} />
      <ItemDataVertical itemLabel="Agama" itemValue={religion[user.religion]} />
      <ItemDataVertical itemLabel="Status" itemValue={user.status === 1 ? 'Aktif' : 'Tidak Aktif'} />
    </Card>
  );
}
