import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// utils
import { defaultAvatar } from '../../../utils/constant';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Beranda',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profil',
    icon: 'eva:person-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  /**
   * TODO: Component variable
   */
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  /**
   * TODO: Data variable
   */
  const user = JSON.parse(localStorage.getItem('user'));
  const auth = JSON.parse(localStorage.getItem('authorization'));

  /**
   * TODO: Handle component, sort data, and pagination
   */
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenu = (label) => {
    setOpen(null);
    switch (label) {
      case 'Beranda':
        navigate('/beranda');
        break;

      case 'Profil':
        navigate('/profil');
        break;

      default:
        break;
    }
  };

  /**
   * TODO: Handle calling API
   */
  const handleLogout = async () => {
    try {
      // call api
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/api/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );

      localStorage.removeItem('authorization');
      localStorage.removeItem('user');
      localStorage.removeItem('permissions');
      navigate('/login', { replace: true });
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.removeItem('authorization');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        navigate('/login', { replace: true });
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          alt={`Foto ${user.name}`}
          src={defaultAvatar[user?.gender] ?? '/assets/images/avatars/avatar_default.jpg'}
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleMenu(option.label)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack onClick={handleLogout}>
          <MenuItem onClick={handleClose} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Stack>
      </Popover>
    </>
  );
}
