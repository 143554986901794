import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

TitlePage.prototype = {
  title: PropTypes.string,
};

function TitlePage({ title }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
    </Stack>
  );
}

export default TitlePage;
