import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Select,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  CircularProgress,
  FormHelperText,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

ModalEdit.prototype = {
  data: PropTypes.object,
  row: PropTypes.object,
  open: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateData: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function ModalEdit({ data, row, open, isSubmit, onClose, onUpdateData }) {
  /**
   ** Component variable
   */
  const [openRoom, setOpenRoom] = useState(false);

  /**
   ** Data variable
   */
  const [assessment, setAssessment] = useState([]);
  const groupByRoom = data.room.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setErrors({});
    setTouched({
      date: false,
      time_start_at: false,
      time_end_at: false,
      material_real: false,
      assessment_real: false,
      method: false,
      room: false,
      ol_platform: false,
      ol_links: false,
    });
  };

  /**
   ** Handle data
   */
  const checkError = (error, touch) => Boolean(error) && Boolean(touch);

  const changeAssessment = (event) => {
    const {
      target: { value },
    } = event;

    setFieldValue('assessment_real', typeof value === 'string' ? value.split(', ') : value);
  };

  const handleUpdateData = async () => {
    await onUpdateData(values);
  };

  useEffect(() => {
    setAssessment(data.class.course_plan_assessments?.map((row) => row.name) ?? []);
  }, [data.class]);

  useEffect(() => {
    if (row) {
      setValues({
        date: row?.meeting_start_at ? dayjs(row.meeting_start_at) : dayjs(),
        time_start_at: row?.meeting_start_at ? dayjs(row.meeting_start_at) : '',
        time_end_at: row?.meeting_end_at ? dayjs(row.meeting_end_at) : '',
        material_real: row.material_real,
        assessment_real: row.assessment_real.split(', '),
        method: row.method,
        room: row?.room_id ? groupByRoom.filter((room) => room.id === row.room_id)[0] : null,
        ol_platform: row?.ol_platform ?? '',
        ol_links: row?.ol_links ?? '',
      });
    }
  }, [row]);

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        date: '',
        time_start_at: '',
        time_end_at: '',
        material_real: '',
        assessment_real: '',
        method: '',
        room: '',
        ol_platform: '',
        ol_links: '',
      },
      validationSchema: yup.object().shape({
        date: yup
          .date()
          .typeError('Tanggal tidak valid')
          .max(dayjs(), 'Tanggal tidak boleh melewati hari ini')
          .required('Tanggal tidak boleh kosong'),
        time_start_at: yup.date().typeError('Waktu tidak valid').required('Waktu mulai tidak boleh kosong'),
        time_end_at: yup.date().typeError('Waktu tidak valid').required('Waktu selesai tidak boleh kosong'),
        material_real: yup.string().required('Realisasi pembelajaran tidak boleh kosong'),
        assessment_real: yup.array().min(1, 'Assessment pembelajaran tidak boleh kosong'),
        room: yup.object().when('method', {
          is: (value) => value === 1,
          then: (schema) => schema.required('Ruangan tidak boleh kosong'),
          otherwise: (schema) => schema.nullable(),
        }),
        ol_platform: yup.string().when('method', {
          is: (value) => value === 0,
          then: (schema) => schema.required('Media tidak boleh kosong'),
          otherwise: (schema) => schema.nullable(),
        }),
        ol_links: yup
          .string()
          .url('Tautan tidak valid')
          .when('method', {
            is: (value) => value === 0,
            then: (schema) => schema.required('Tautan tidak boleh kosong'),
            otherwise: (schema) => schema.nullable(),
          }),
      }),
      onSubmit: handleUpdateData,
    });

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Edit Data'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <InputLabel sx={{ color: '#000' }}>
            {'Tanggal '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="date"
              name="date"
              label="Tanggal"
              value={values.date}
              onChange={(value) => setFieldValue('date', value)}
              slotProps={{
                textField: {
                  error: checkError(errors.date, touched.date),
                  helperText: checkError(errors.date, touched.date) ? errors.date : '',
                },
              }}
              disableFuture
              format="DD/MM/YYYY"
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
            />
          </LocalizationProvider>

          <InputLabel sx={{ color: '#000', marginTop: 3 }}>
            {'Jam mulai '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              id="time_start_at"
              name="time_start_at"
              label="Jam mulai"
              value={values.time_start_at}
              onChange={(value) => setFieldValue('time_start_at', value)}
              slotProps={{
                textField: {
                  error: checkError(errors.time_start_at, touched.time_start_at),
                  helperText: checkError(errors.time_start_at, touched.time_start_at) ? errors.time_start_at : '',
                },
              }}
              ampm={false}
              format="HH:mm"
              timeSteps={{ hours: 1, minutes: 1 }}
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
            />
          </LocalizationProvider>

          <InputLabel sx={{ color: '#000', marginTop: 3 }}>
            {'Jam selesai '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              id="time_end_at"
              name="time_end_at"
              label="Jam selesai"
              value={values.time_end_at}
              onChange={(value) => setFieldValue('time_end_at', value)}
              slotProps={{
                textField: {
                  error: checkError(errors.time_end_at, touched.time_end_at),
                  helperText: checkError(errors.time_end_at, touched.time_end_at) ? errors.time_end_at : '',
                },
              }}
              ampm={false}
              format="HH:mm"
              timeSteps={{ hours: 1, minutes: 1 }}
              sx={{ width: '100%', marginTop: 1, marginBottom: 1 }}
            />
          </LocalizationProvider>

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor="material_real">
            {'Realisasi pembelajaran '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="material_real"
            name="material_real"
            value={values.material_real}
            onChange={handleChange}
            error={checkError(errors.material_real, touched.material_real)}
            helperText={checkError(errors.material_real, touched.material_real) ? errors.material_real : ''}
            label="Realisasi pembelajaran"
            type="text"
            fullWidth
            rows={4}
          />

          <Typography mt={3}>
            {'Assessment pembelajaran '}
            <span style={{ color: '#FF4842' }}>*</span>
          </Typography>
          <FormControl
            fullWidth
            sx={{ marginTop: 1 }}
            error={checkError(errors.assessment_real, touched.assessment_real)}
          >
            <InputLabel id="label_assessment_real">Assessment pembelajaran</InputLabel>
            <Select
              labelId="label_assessment_real"
              id="assessment_real"
              multiple
              value={values.assessment_real}
              onChange={changeAssessment}
              input={<OutlinedInput id="placeholder_assessment_real" label="Assessment pembelajaran" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {assessment.map((name, index) => (
                <MenuItem key={index} value={name}>
                  <Checkbox checked={values.assessment_real.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {checkError(errors.assessment_real, touched.assessment_real) ? errors.assessment_real : ''}
            </FormHelperText>
          </FormControl>

          <Typography mt={3}>
            {'Metode '}
            <span style={{ color: '#FF4842' }}>*</span>
          </Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="label_method">Metode</InputLabel>
            <Select
              labelId="label_method"
              id="method"
              name="method"
              value={values.method}
              onChange={handleChange}
              label="Metode"
            >
              <MenuItem value={1}>Tatap Muka</MenuItem>
              <MenuItem value={0}>Daring</MenuItem>
            </Select>
          </FormControl>

          {values.method === 1 && (
            <>
              <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'room'}>
                {'Ruangan '}
                <span style={{ color: '#FF4842' }}>*</span>
              </InputLabel>
              <Autocomplete
                id="room"
                name="room"
                fullWidth
                sx={{ marginTop: 1 }}
                open={openRoom}
                onOpen={() => setOpenRoom(true)}
                onClose={() => setOpenRoom(false)}
                isOptionEqualToValue={(option, value) => value !== null && option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                options={groupByRoom.toSorted((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                groupBy={(option) => option.firstLetter}
                loading={openRoom && !data.room.length}
                value={values.room}
                onChange={(event, value) => setFieldValue('room', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkError(errors.room, touched.room)}
                    helperText={checkError(errors.room, touched.room) ? errors.room : ''}
                    label="Ruangan"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {openRoom && !data.room.length ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </>
          )}

          {values.method === 0 && (
            <>
              <Typography mt={3}>
                {'Media '}
                <span style={{ color: '#FF4842' }}>*</span>
              </Typography>
              <FormControl fullWidth sx={{ marginTop: 1 }} error={checkError(errors.ol_platform, touched.ol_platform)}>
                <InputLabel id="label_ol_platform">Media</InputLabel>
                <Select
                  labelId="label_ol_platform"
                  id="ol_platform"
                  name="ol_platform"
                  value={values.ol_platform}
                  onChange={handleChange}
                  label="Media"
                >
                  <MenuItem value={'Zoom'}>Zoom</MenuItem>
                  <MenuItem value={'Ms. Teams'}>Ms. Teams</MenuItem>
                  <MenuItem value={'Google Meet'}>Google Meet</MenuItem>
                </Select>
                <FormHelperText>
                  {checkError(errors.ol_platform, touched.ol_platform) ? errors.ol_platform : ''}
                </FormHelperText>
              </FormControl>

              <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'ol_links'}>
                {'Tautan '}
                <span style={{ color: '#FF4842' }}>*</span>
              </InputLabel>
              <TextField
                margin="dense"
                id="ol_links"
                name="ol_links"
                label="Tautan"
                type="url"
                fullWidth
                value={values.ol_links}
                onChange={handleChange}
                error={checkError(errors.ol_links, touched.ol_links)}
                helperText={checkError(errors.ol_links, touched.ol_links) ? errors.ol_links : ''}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
