import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead, TableListToolbar } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: 'Kode', alignRight: false },
  { id: 'name', label: 'Nama Mata Kuliah', alignRight: false },
  { id: 'alias_name', label: 'Nama Alias', alignRight: false },
  { id: 'credit', label: 'SKS', alignRight: false },
  { id: 'semester', label: 'Semester', alignRight: false },
  { id: 'rev', label: 'Tahun', alignRight: false },
  { id: '' },
];

MataKuliahTable.prototype = {
  data: PropTypes.node.isRequired,
  periode: PropTypes.string.isRequired,
  onChangePeriode: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
  onOpenAction: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function MataKuliahTable({ data, periode, onLoadData, onChangePeriode, onOpenAction }) {
  /**
   ** Component variable
   */
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('rev');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.coursePlan.length) : 0;
  const filteredUsers = applySortFilter(data.coursePlan, getComparator(order, orderBy), filterName, 'name');
  const isNotFound = !filteredUsers.length && !!filterName;

  /**
   ** Handle data
   */
  const handleChangePeriode = async (event) => {
    setPage(0);

    setIsLoading(true);
    onChangePeriode(event);

    const split = event.target.value.split('-');
    const obj = {
      semester_type: split[0],
      year: split[1],
    };

    await onLoadData(obj);
    setIsLoading(false);
  };

  const handleLoadData = async () => {
    await onLoadData(data.periode[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }}>
        <Box ml={3} mt={{ xs: 3, sm: 0 }} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="label_list_periode">Periode</InputLabel>
            {Boolean(data.periode.length) && (
              <Select
                labelId="label_list_periode"
                id="list_periode"
                value={periode}
                label="Periode"
                name="periode"
                onChange={handleChangePeriode}
              >
                {data.periode.map((row, index) => (
                  <MenuItem key={index} value={`${row.semester_type}-${row.year}`}>
                    {`${row.semester_type} ${row.year}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>

        <TableListToolbar filterData={filterName} onFilterData={handleFilterByName} placeholder={'Mata Kuliah...'} />
      </Stack>

      {/**
       * Data Rps Mata Kuliah
       */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredUsers.length}
              onRequestSort={handleRequestSort}
            />

            {isLoading ? (
              <LoadingTable headLength={TABLE_HEAD.length} isLoading={isLoading} />
            ) : (
              <>
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row.id} tabIndex={-1}>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.alias_name ?? '-'}</TableCell>
                      <TableCell align="left">{row.credit}</TableCell>
                      <TableCell align="left">{row.semester}</TableCell>
                      <TableCell align="left">{row.rev}</TableCell>

                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={(event) => onOpenAction(event, row)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={TABLE_HEAD.length} />
                    </TableRow>
                  )}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.coursePlan.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* jika hasil pencarian tidak ditemukan */}
                {isNotFound && data.coursePlan.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ditemukan hasil pencarian &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Periksa kembali kata kunci yang anda masukkan.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
