import Proptypes from 'prop-types';
// @mui
import { Card, Stack, Button } from '@mui/material';
// components
import Iconify from '../../components/iconify';

Menu.prototype = {
  onOpenInsert: Proptypes.func.isRequired,
};

export default function Menu({ onOpenInsert }) {
  return (
    <Card sx={{ padding: 3, marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="space-between" justifyContent="end" spacing={1}>
        <Button variant="contained" onClick={onOpenInsert} startIcon={<Iconify icon="eva:plus-fill" />}>
          Tambah
        </Button>
      </Stack>
    </Card>
  );
}
