import PropTypes from 'prop-types';
// @mui
import { Grid, Typography } from '@mui/material';

ItemData.prototype = {
  itemLabel: PropTypes.string.isRequired,
  itemValue: PropTypes.any.isRequired,
  objectKey: PropTypes.string,
  objectValue: PropTypes.string,
  sx: PropTypes.any,
};

function ItemData({ itemLabel, itemValue, objectKey, objectValue, sx }) {
  const checkDataType = (value) => {
    // data array
    if (Array.isArray(value) && !value.length) {
      return <Typography>{'-'}</Typography>;
    }

    if (Array.isArray(value)) {
      return value.map((item) => <Typography key={item[objectKey]}>{item[objectValue]}</Typography>);
    }

    return <Typography>{value ?? '-'}</Typography>;
  };

  return (
    <Grid container sx={{ marginTop: 1, ...sx }}>
      <Grid item sm={3} xs={12}>
        <Typography variant="subtitle1">{itemLabel}</Typography>
      </Grid>
      <Grid item xs>
        {checkDataType(itemValue)}
      </Grid>
    </Grid>
  );
}

export default ItemData;
