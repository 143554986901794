import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

// ----------------------------------------------------------------------

CPLTable.prototype = {
  data: PropTypes.object.isRequired,
  curriculum: PropTypes.number.isRequired,
  reload: PropTypes.number.isRequired,
  canModify: PropTypes.bool,
  onChangeCurriculum: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
  onOpenAction: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function CPLTable({
  data,
  curriculum,
  reload,
  canModify = false,
  onChangeCurriculum,
  onLoadData,
  onOpenAction,
}) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredUsers = applySortFilter(data.plo, getComparator(order, orderBy));

  const modifyHeader = () => {
    const modifiedHeaderTable = [
      { id: 'code', label: 'Kode', alignRight: false },
      { id: 'outcome', label: 'Capaian Lulusan', alignRight: false },
      { id: 'min_grade', label: 'Nilai Minimal', alignRight: false },
    ];

    if (canModify) {
      modifiedHeaderTable.push({ id: '' });
    }

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const handleChangeCurriculum = async (event) => {
    setIsLoading(true);
    onChangeCurriculum(event);

    await onLoadData(parseInt(event.target.value, 10));
    setIsLoading(false);
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    await onLoadData(curriculum);
    setIsLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, [reload]);

  useLayoutEffect(() => {
    modifyHeader();
  }, []);

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ my: 3 }}>
        <Box ml={3} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="label_list_curriculum">Kurikulum</InputLabel>
            {Boolean(data.curriculum.length) && (
              <Select
                labelId="label_list_curriculum"
                id="list_curriculum"
                value={curriculum}
                label="Kurikulum"
                name="curriculum"
                onChange={handleChangeCurriculum}
              >
                {data.curriculum.map((row, index) => (
                  <MenuItem key={row.id} value={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
      </Stack>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={filteredUsers.length}
              onRequestSort={handleRequestSort}
            />

            {isLoading ? (
              <LoadingTable headLength={tableHead.length} isLoading={isLoading} />
            ) : (
              <>
                <TableBody>
                  {filteredUsers.map((row) => (
                    <TableRow hover key={row.id} tabIndex={-1}>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.outcome}</TableCell>
                      <TableCell align="left">{row.min_grade}</TableCell>
                      {canModify && (
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => onOpenAction(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.plo.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ pt: 4, pb: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}
