export const maritalStatus = {
  1: 'Belum Menikah',
  2: 'Menikah',
  3: 'Janda/Duda',
};

export const religion = {
  1: 'Islam',
  2: 'Kristen Protestan',
  3: 'Kristen Katolik',
  4: 'Hindu',
  5: 'Budha',
};

export const gender = {
  M: 'Laki-laki',
  F: 'Perempuan',
};

export const defaultAvatar = {
  M: '/assets/images/avatars/avatar_12.jpg',
  F: '/assets/images/avatars/avatar_2.jpg',
};

export const gradeConvertion = {
  A: { min: 80, max: 100 },
  'A-': { min: 75, max: 80 },
  'B+': { min: 70, max: 75 },
  B: { min: 65, max: 70 },
  'B-': { min: 60, max: 65 },
  'C+': { min: 55, max: 60 },
  C: { min: 50, max: 55 },
  D: { min: 45, max: 50 },
  E: { min: 0, max: 45 },
};

export const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDitnI3UYRYCFuh
YGofpgzqtSNCgONrQ92mG1n7GYrEWlffg6ev4n2dHaVRVVFvG4O32oqc0F3gf2ZE
x5OwJLJtsuu944bk95n0Qx88IKGXZvNzGdzynUF6E2FOQiMwT8RwRn7UTTMrdReZ
q7SkJVM7sp6Sq/38/BPwGB66pXy9l/pibarT7YXW1bC6EDFJ5AR69razPgrc2KQR
APtcnL/JMv9OjNNwqLcHzKWWUGnviPXe5K1AZPoUS5GM9Ox37AdE5pEw5RaztIG9
b87A9oPhCpqbQQyGwoSK7C8vnK17E28NOuETLTEkRvFCAozWjk8ac8nVBDBr07eB
F+OwtoApAgMBAAECggEBAMdJi4MXFBfyWOG1h/QEGiyjR7ho5gBfatJeDq3MmQ/b
Po1LBLd2h+b5rV2eevQJIrMV12zbOPpj4vko2qkmyPXnJpUIEYbqJBn0ett277TQ
GcafAHsAQxNVP970AkywgcQUI97pccaMqk86AZOc/9plPEFAEQc2ayxNd42odMuO
ykoRAPlv/IfhRcLk32TLjeLB7Hqug7W8aXd/vaKMtjIA2xz0tGPyEHUXY65jJ9sT
WYoHOlYBMsEGFqKqFDMnn/0uLc/MylyvOmwE2z7YI+Ydcj8fd1RnWLpqlZUXqdHA
QINn+8a+m1CZONAmThWKhNeY447oVw69G997EO7Jl4ECgYEA8w4Dutvj+BGHJku6
PLjZxJm8Iev+3sMHI6Fjd9etl5kpuKEbjcSOlkvNJ+NOOTyt+9qzoJIlBsdfnZaX
K4jmvJ3+LF3QkKoU3tHpsVnBnAj8hcYd/W071XEV0ekB3baTcLT5Cu94BoTBE3Dp
ysWrC4cnKh61Nc8p/sAPe08/SFkCgYEA7smcqsOwq4f3VXBEFxZLhU/f5qSQNhEr
l+iFGWRwoZeX2U3oN2sUPzsrgL+n/Ed2XOowFEUBj6utLXl3jf5yGcNA9BCA4jre
/GzITsrVdaAH9xVlFQvIdJGjtKMNKbq9viJTW30eYYLszLcwamHwZwXNeD+DohMH
yMaMPRch/FECgYAU1sfFFqNWGpKe2vFN0X+CVrgFpIlGqiwLhwPTO5+7lrs4BEAr
iIW9JySg3eFQEP7N0DOEeeNI0IFHLqkpZ2R5GYa4YRbHwH1nmVHTDeIGsdKM2a6q
ktoFc9uuEq9BRi0gBbQwltWQoYM4Wn8BDq+anmziNJ3OzkNIf0kUhnfjiQKBgD7x
P+23VYtxdJ290QTw6IlEa7WFLUpDLmWFcggmdJXzuqAaLBrni9e7BhWS73nNcIK/
bFr7cZToYOa6vOvd5T+rOyTacM6xS/PnzMkQKEIwI8lah2GL0JQBi9BipmaYhcln
jOdxzJ9Rfo/bvdRc6pyNaALJYy5Gq+edhnvX7CpxAoGAB7UUyzobXjf/JvdXjVLh
6pTKY+jA/teqHr8gCjjqYapH54/UX6rcrKzQx5garkOV99NHXxuzHBhVZiNQhIAv
BJ2dhQmDGp+DbSXYAtrz7Gsn42LSKpAdpzeEIW44S/hywGihmYIZS0bZuPW7GM8w
NipBQIndbFSuK4Ki+HRbgX4=
-----END PRIVATE KEY-----`;

export const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApSdza/natV7GDF8JPB8/
u+WxH6w9VWwpZMEqBcgBmqEU0SHCExxrtBs3xOECCM4NrLbo7UNrUXIpPblIX3Z5
r9tndHm0ZeD8srKsxJtxWjxXPPuv5u2Xp4Y9hfmIW14VlWUIDqZ/XaOhU3IbGqEg
+t9ALactNVBrnvucmkZi0xT80eYBu8S3DQftlwKn5bBHRSNFknFtT7wnvVsZGiJ4
6ELkTF9eqKAkTcxEI4d0NMa9y2AL1D179e3k3waFMhuP7K5ica7b/3ca7Ae2EY5V
4RPNaXatDp4Bt9vYCwvtxObFMDlcHIcezsKp4qBYjWBZh05axVYFAvtN8OZTwyL9
hwIDAQAB
-----END PUBLIC KEY-----`;
