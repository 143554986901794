import Proptypes from 'prop-types';
// @mui
import { Popover, MenuItem } from '@mui/material';
// components
import Iconify from '../../components/iconify';

ModalAction.prototype = {
  open: Proptypes.node.isRequired,
  onClose: Proptypes.func.isRequired,
  onOpenUpdate: Proptypes.func.isRequired,
  onOpenConfirm: Proptypes.func.isRequired,
};

export default function ModalAction({ open, onClose, onOpenUpdate, onOpenConfirm }) {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 140,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      <MenuItem onClick={onOpenUpdate}>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Edit
      </MenuItem>

      <MenuItem sx={{ color: 'error.main' }} onClick={onOpenConfirm}>
        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
        Delete
      </MenuItem>
    </Popover>
  );
}
