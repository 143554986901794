import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

ProtectedRoute.propTypes = {
  children: PropTypes.element,
  replacePage: PropTypes.bool,
};
export default function ProtectedRoute({ children, replacePage = false }) {
  /**
   ** Data variable
   */
  const auth = JSON.parse(localStorage.getItem('authorization'));

  /**
   ** Check authentication
   */
  if (!auth || auth?.expires_at < Date.now()) {
    return <Navigate to="/login" replace={replacePage} />;
  }

  return children || <Outlet />;
}
