import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
//
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  /**
   ** Component variable
   */
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  /**
   ** Data variable
   */
  const auth = JSON.parse(localStorage.getItem('authorization'));

  /**
   ** Handle component, sort data, and pagination
   */
  const handleLogout = () => {
    localStorage.removeItem('authorization');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    sessionStorage.setItem('pesan', 'Sesi anda telah berakhir');
    navigate('/login', { replace: true });
  };

  const timeout = () => {
    if (auth?.expires_at) {
      setTimeout(() => {
        handleLogout();
      }, auth.expires_at - Date.now());
    }
  };

  useEffect(() => {
    timeout();
  }, []);

  return (
    <StyledRoot>
      {auth.token ? <Header onOpenNav={() => setOpen(true)} /> : false}

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>

      {/* alert notification */}
      <SnackbarProvider maxSnack={3} />
    </StyledRoot>
  );
}
