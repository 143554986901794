import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container, Dialog } from '@mui/material';
// components
import { enqueueSnackbar } from 'notistack';
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { BAPTable, DetailMeeting } from '../../sections/bap';
import Menu from './Menu';
// services
import GatewayHttpService from '../../services/GatewayHttpService';
import ModalVerify from './ModalVerify';

export default function MonevBAPPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openDetail, setOpenDetail] = useState(null);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({
    class: {},
    bap: [],
  });
  const [row, setRow] = useState(null);
  const [kelas, setKelas] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenDetail = (event, row) => {
    setRow(row);
    setOpenDetail(event.currentTarget);
  };

  const handleCloseDetail = () => {
    setOpenDetail(null);
    setRow(null);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
  };

  const handleCloseVerify = () => {
    setOpenVerify(false);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
    window.URL.revokeObjectURL(pdfUrl);
    setPdfUrl('');
  };

  /**
   ** Handle data
   */
  const changeKelas = (event) => {
    setKelas(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getClass = async () => {
    setIsLoading(true);
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/class?assessment=true`
    );
    setData((data) => ({ ...data, class: response.data.data }));
    setIsLoading(false);
  };

  const getBap = async (kelas = 0) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/bap`
    );
    setData((data) => ({ ...data, bap: response.data.data }));
  };

  const verifyData = async (values) => {
    setIsSubmit(true);
    const response = await GatewayHttpService.securePatch(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/bap/verify`,
      values
    );

    handleCloseVerify();
    setIsSubmit(false);

    if (response?.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      getClass();
    }
  };

  const print = async () => {
    setIsPrint(true);
    const response = await GatewayHttpService.secureGetFile(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/bap/print`
    );

    setPdfUrl(window.URL.createObjectURL(response?.data));
    setIsPrint(false);
    setOpenPdf(true);
  };

  useEffect(() => {
    getClass();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Berita Acara Perkuliahan" />

        <Menu
          data={data}
          isPrint={isPrint}
          onPrint={print}
          onOpenVerify={handleOpenVerify}
          printProps={{ disabled: isPrint || !data.class.class_courses?.length }}
        />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card>
            {/**
             * Data BAP
             */}
            <BAPTable
              data={data}
              kelas={kelas}
              onChangeKelas={changeKelas}
              onLoadData={getBap}
              onOpenDetail={handleOpenDetail}
            />
          </Card>
        )}
      </Container>

      {/**
       * Popup detail pertemuan
       */}
      <DetailMeeting row={row} open={openDetail} onClose={handleCloseDetail} />

      {/**
       * Modal verifikasi BAP
       */}
      <ModalVerify
        data={data}
        kelas={kelas}
        open={openVerify}
        isSubmit={isSubmit}
        onClose={handleCloseVerify}
        onVerifyData={verifyData}
      />

      {/**
       * Popup viewer pdf
       */}
      <Dialog id="dialog-pdf" fullWidth maxWidth={'lg'} open={openPdf} onClose={handleClosePdf}>
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="Berita Acara Perkuliahan (BAP).pdf"
          style={{ border: 'none' }}
        />
      </Dialog>
    </>
  );
}
