import { useEffect } from 'react';
import Proptypes from 'prop-types';
// @mui
import {
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';

ModalUpdate.prototype = {
  data: Proptypes.object.isRequired,
  row: Proptypes.object.isRequired,
  open: Proptypes.bool.isRequired,
  isSubmit: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  onUpdateData: Proptypes.func.isRequired,
};

export default function ModalUpdate({ data, row, open, isSubmit, onClose, onUpdateData }) {
  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setErrors({});
    setTouched({ curriculum_id: false, code: false, profile: false });
  };

  /**
   ** Handle data
   */
  const handleUpdateData = async () => {
    await onUpdateData(values);
  };

  useEffect(() => {
    if (row) {
      setValues({
        curriculum_id: row.curriculum_id,
        code: row.code,
        profile: row.profile,
      });
    }
  }, [row]);

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      curriculum_id: '',
      code: '',
      profile: '',
    },
    validationSchema: yup.object().shape({
      curriculum_id: yup.number().required('Kurikulum tidak boleh kosong'),
      code: yup.string().required('Kode tidak boleh kosong'),
      profile: yup.string().required('Profil prodi tidak boleh kosong'),
    }),
    onSubmit: handleUpdateData,
  });

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Edit Data'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography sx={{ color: '#000' }}>
            {'Kurikulum '}
            <span style={{ color: '#FF4842' }}>*</span>
          </Typography>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="curriculum_id">Kurikulum</InputLabel>
            <Select
              labelId="curriculum_id"
              id="curriculum_id"
              name="curriculum_id"
              value={values.curriculum_id}
              error={Boolean(errors.curriculum_id) && Boolean(touched.curriculum_id)}
              onChange={handleChange}
              label="Kurikulum"
            >
              {data.curriculum.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            {Boolean(errors.curriculum_id) && Boolean(touched.curriculum_id) && (
              <FormHelperText error>{errors.curriculum_id}</FormHelperText>
            )}
          </FormControl>

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'code'}>
            {'Kode '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            margin="dense"
            id="code"
            name="code"
            label="Kode"
            type="text"
            value={values.code}
            fullWidth
            error={Boolean(errors.code) && Boolean(touched.code)}
            helperText={Boolean(errors.code) && Boolean(touched.code) ? errors.code : ''}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'profile'}>
            {'Profil prodi '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="profile"
            name="profile"
            label="Profil prodi"
            value={values.profile}
            type="text"
            fullWidth
            rows={4}
            error={Boolean(errors.profile) && Boolean(touched.profile)}
            helperText={Boolean(errors.profile) && Boolean(touched.profile) ? errors.profile : ''}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
