import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Stack,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

BAPTable.prototype = {
  data: PropTypes.object.isRequired,
  kelas: PropTypes.number.isRequired,
  reload: PropTypes.number.isRequired,
  canModify: PropTypes.bool,
  onChangeKelas: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
  onOpenAction: PropTypes.func,
  onOpenDetail: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function BAPTable({
  data,
  kelas,
  reload,
  canModify = false,
  onLoadData,
  onChangeKelas,
  onOpenAction,
  onOpenDetail,
}) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.bap.length) : 0;

  const modifyHeader = () => {
    const modifiedHeaderTable = [
      { id: 'meet_no', label: 'Pertemuan ke', alignRight: false },
      { id: 'time', label: 'Waktu Pelaksanaan', alignRight: false },
      { id: 'material_plan', label: 'Rencana Pembelajaran', alignRight: false },
      { id: 'material_real', label: 'Realisasi Pembelajaran', alignRight: false },
      { id: 'assessment_real', label: 'Assessment', alignRight: false },
      { id: 'method', label: 'Metode', alignRight: false },
    ];

    if (canModify) {
      modifiedHeaderTable.push({ id: '' });
    }

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const handleChangeKelas = async (event) => {
    setPage(0);

    setIsLoading(true);
    onChangeKelas(event);

    await onLoadData(parseInt(event.target.value, 10));
    setIsLoading(false);
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    await onLoadData(kelas);
    setIsLoading(false);
  };

  const checkMeetMethod = (method) => method !== null;

  useEffect(() => {
    handleLoadData();
  }, [reload]);

  useLayoutEffect(() => {
    modifyHeader();
  }, []);

  return (
    <>
      <Stack direction="row" alignItems="center" my={3}>
        <Box ml={3} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="list-kelas">Kelas</InputLabel>
            {data.class.class_courses?.length && (
              <Select
                labelId="list-kelas"
                id="list-kelas"
                value={kelas}
                label="Kelas"
                name="kelas"
                onChange={handleChangeKelas}
              >
                {data.class.class_courses.map((row, index) => (
                  <MenuItem key={row.id} value={index}>
                    <Typography>
                      {`${row.name} `}

                      {Boolean(row.meeting_verified) && (
                        <Iconify icon="ph:seal-check-fill" sx={{ width: 15, color: '#229A16' }} />
                      )}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
      </Stack>

      {/**
       * Data BAP
       */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead headLabel={tableHead} rowCount={data.bap.length} />

            {isLoading ? (
              <LoadingTable headLength={tableHead.length} isLoading={isLoading} />
            ) : (
              <>
                <TableBody>
                  {data.bap.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row.week} tabIndex={-1}>
                      <TableCell align="center">{row.week}</TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {row.meeting_start_at
                            ? new Date(row.meeting_start_at).toLocaleDateString('id-ID', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })
                            : '-'}
                        </Typography>
                        <Typography variant="body2">
                          {row.meeting_start_at && row.meeting_end_at
                            ? `${new Date(row.meeting_start_at).getHours().toString().padStart(2, '0')}.${new Date(
                                row.meeting_start_at
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')} - ${new Date(row.meeting_end_at)
                                .getHours()
                                .toString()
                                .padStart(2, '0')}.${new Date(row.meeting_end_at)
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')}`
                            : ''}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        {row.material_plan
                          ? row.material_plan.split(';;').map((row, index, elements) => (
                              <Typography variant="body2" key={index}>
                                {row}
                                {index !== elements.length - 1 && (
                                  <>
                                    <br />
                                    <br />
                                  </>
                                )}
                              </Typography>
                            ))
                          : '-'}
                      </TableCell>

                      <TableCell align="left">{row.material_real ?? '-'}</TableCell>
                      <TableCell align="left">{row.assessment_real ?? '-'}</TableCell>

                      <TableCell>
                        <Typography
                          variant="body2"
                          sx={{
                            fontStyle: checkMeetMethod(row.method) && 'italic',
                            color: checkMeetMethod(row.method) && '#1976d2',
                            cursor: checkMeetMethod(row.method) && 'pointer',
                            textDecoration: checkMeetMethod(row.method) && 'underline',
                          }}
                          onClick={(event) => (checkMeetMethod(row.method) ? onOpenDetail(event, row) : false)}
                        >
                          {row.method === 1 && 'Tatap Muka'}
                          {row.method === 0 && 'Daring'}
                          {!checkMeetMethod(row.method) && '-'}
                        </Typography>
                      </TableCell>

                      {canModify && (
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => onOpenAction(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={tableHead.length} />
                    </TableRow>
                  )}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.bap.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={data.bap.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
