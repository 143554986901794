import { useNavigate } from 'react-router-dom';
// @mui
import { Card, Stack, Button } from '@mui/material';
// components
import Iconify from '../../components/iconify';

export default function Menu() {
  /**
   ** Component variable
   */
  const navigate = useNavigate();

  /**
   ** Data variable
   */

  return (
    <Card sx={{ padding: 3, marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:arrow-circle-left-fill" />}
          onClick={() => navigate('/beranda')}
        >
          Kembali
        </Button>
      </Stack>
    </Card>
  );
}
