import PropTypes from 'prop-types';
// @mui
import { Table, Typography, TableRow, TableBody, TableCell, TableContainer, Paper } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'code', label: 'Kode', alignRight: false },
  { id: 'name', label: 'Capaian Lulusan', alignRight: false },
];

CPLTable.prototype = {
  data: PropTypes.array.isRequired,
};

// ----------------------------------------------------------------------

export default function CPLTable({ data }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={TABLE_HEAD} rowCount={data.length} />
          <TableBody>
            {data.map((row) => (
              <TableRow hover key={row.id} tabIndex={-1} sx={{ borderBottom: 2 }}>
                <TableCell align="left" sx={{ minWidth: '100px' }}>
                  {row.code}
                </TableCell>
                <TableCell align="left">{row.outcome}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {/* jika data tidak ada */}
          {!data.length && (
            <TableBody>
              <TableRow sx={{ borderBottom: 2 }}>
                <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Data tidak ditemukan
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
