import { useEffect, useLayoutEffect, useState } from 'react';
import Proptypes from 'prop-types';
// @mui
import {
  Stack,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Grid,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead, TableListToolbar } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

// ----------------------------------------------------------------------

NilaiTable.prototype = {
  data: Proptypes.object.isRequired,
  kelas: Proptypes.number.isRequired,
  reload: Proptypes.number.isRequired,
  canModify: Proptypes.bool,
  onLoadData: Proptypes.func.isRequired,
  onChangeKelas: Proptypes.func.isRequired,
  onOpenAction: Proptypes.func,
};

// ----------------------------------------------------------------------

export default function NilaiTable({
  data,
  kelas,
  reload,
  canModify = false,
  onChangeKelas,
  onLoadData,
  onOpenAction,
}) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('student_nim');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.student.study_plans.length) : 0;
  const filteredUsers = applySortFilter(
    data.student.study_plans,
    getComparator(order, orderBy),
    filterName,
    'student_name'
  );
  const isNotFound = !filteredUsers.length && !!filterName;

  const modifyHeader = () => {
    const modifiedHeaderTable = [
      { id: 'student_nim', label: 'Nim', alignRight: false },
      { id: 'student_name', label: 'Nama Mahasiswa', alignRight: false },
    ];

    data.class.course_plan_los.forEach((plo) =>
      modifiedHeaderTable.push({ id: plo.code.toLowerCase(), label: plo.code, alignRight: false })
    );

    modifiedHeaderTable.push({ id: 'final_grade', label: 'Nilai Akhir', alignRight: false });
    modifiedHeaderTable.push({ id: 'letter_grade', label: 'Nilai Huruf', alignRight: false });
    modifiedHeaderTable.push({ id: '' });

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const handleChangeKelas = async (event) => {
    setPage(0);

    setIsLoading(true);
    onChangeKelas(event);

    await onLoadData(parseInt(event.target.value, 10));
    setIsLoading(false);
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    await onLoadData(kelas);
    setIsLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, [reload]);

  useLayoutEffect(() => {
    modifyHeader();
  }, []);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }}>
        <Box ml={3} mt={{ xs: 3, sm: 0 }} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="label_list_class">Kelas</InputLabel>
            {data.class.class_courses?.length && (
              <Select
                labelId="label_list_class"
                id="list_class"
                value={kelas}
                label="Kelas"
                name="kelas"
                onChange={handleChangeKelas}
              >
                {data.class.class_courses.map((row, index) => (
                  <MenuItem key={row.id} value={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>

        <TableListToolbar filterData={filterName} onFilterData={handleFilterByName} placeholder={'Mahasiswa...'} />
      </Stack>

      {/**
       * Data Mahasiswa dan Nilai
       */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={filteredUsers.length}
              onRequestSort={handleRequestSort}
            />

            {isLoading ? (
              <LoadingTable headLength={tableHead.length} isLoading={isLoading} />
            ) : (
              <>
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row.student_id} tabIndex={-1}>
                      <TableCell align="left">{row.student_nim}</TableCell>
                      <TableCell align="left" sx={{ minWidth: 150 }}>
                        {row.student_name}
                      </TableCell>

                      {row.assessment_plos.map((item) => (
                        <TableCell key={item.id} align="left" sx={{ minWidth: 100 }}>
                          {item.grade}
                        </TableCell>
                      ))}

                      <TableCell align="left">{row.final_grade}</TableCell>
                      <TableCell align="left">{row.letter_grade}</TableCell>

                      {canModify && (
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => onOpenAction(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={tableHead.length} />
                    </TableRow>
                  )}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.student.study_plans.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* jika pencarian tidak ditemukan */}
                {isNotFound && data.student.study_plans.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ditemukan hasil pencarian &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Periksa kembali kata kunci yang anda masukkan.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box sx={{ m: 3 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          *Keterangan:
        </Typography>
        {data.class.course_plan_los.map((lo) => (
          <Grid key={lo.id} container sx={{ marginTop: 1 }}>
            <Grid item sm={2} xs={12}>
              <Typography variant="body2" sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                {lo.code}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">{lo.name}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
}
