import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Button } from '@mui/material';
import { ClockLoader } from 'react-spinners';
// components
import Iconify from '../../components/iconify';

Menu.prototype = {
  data: PropTypes.object.isRequired,
  isDownload: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  onOpenUpload: PropTypes.func.isRequired,
};

export default function Menu({ data, isDownload, onDownload, onOpenUpload }) {
  /**
   ** Component variable
   */
  const navigate = useNavigate();

  /**
   ** Data variable
   */
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  return (
    <Card sx={{ padding: 3, marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:arrow-circle-left-fill" />}
          onClick={() => navigate('/beranda')}
        >
          Kembali
        </Button>

        {permissions.some((permission) => permission === 'assessments_manage') && (
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
            <Button
              variant="contained"
              startIcon={
                isDownload ? (
                  <ClockLoader color={'#308fe8'} size={18} loading={isDownload} />
                ) : (
                  <Iconify icon="mdi:file-download-outline" />
                )
              }
              disabled={!data.class.class_courses?.length || isDownload}
              onClick={onDownload}
              sx={{ marginRight: 1 }}
            >
              Unduh Template
            </Button>

            <Button
              variant="contained"
              startIcon={<Iconify icon="material-symbols:upload-file-outline" />}
              disabled={!data.class.class_courses?.length}
              onClick={onOpenUpload}
              sx={{ marginRight: 1 }}
            >
              Unggah Nilai
            </Button>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
