import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Stack,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Grid,
  Chip,
} from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead, TableListToolbar } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

MonevCplTable.prototype = {
  data: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
};

export default function MonevCplTable({ data, year, onChangeYear, onLoadData }) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nim');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.student.length) : 0;
  const filteredUsers = applySortFilter(data.student, getComparator(order, orderBy), filterName, 'name');
  const isNotFound = !filteredUsers.length && !!filterName;

  const modifyHeader = () => {
    const modifiedHeaderTable = [
      { id: 'name', label: 'Nama Mahasiswa', alignRight: false },
      { id: 'nim', label: 'Nim', alignRight: false },
      { id: 'graduate_year', label: 'Tahun Lulus', alignRight: false },
    ];

    data.plo.forEach((plo) => {
      modifiedHeaderTable.push({ id: plo.code.toLowerCase(), label: plo.code, alignRight: false });
    });

    modifiedHeaderTable.push({ id: 'status', label: 'Status', alignRight: false });

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const handleChangeYear = (event) => {
    setPage(0);
    onChangeYear(event);
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    await onLoadData(year);
    setIsLoading(false);
  };

  const checkStatus = (results) => !results.some((row) => row.flag === false);

  useEffect(() => {
    handleLoadData();
  }, [year]);

  useLayoutEffect(() => {
    modifyHeader();
  }, [data]);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }}>
        <Box ml={3} mt={{ xs: 3, sm: 0 }} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="list-kelas">Angkatan</InputLabel>
            <Select labelId="list-kelas" id="list-kelas" value={year} label="Angkatan" onChange={handleChangeYear}>
              {data.year.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TableListToolbar filterData={filterName} onFilterData={handleFilterByName} placeholder={'Mahasiswa..'} />
      </Stack>

      {/**
       * Data Mahasiswa dan Nilai
       */}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            {isLoading ? (
              <LoadingTable headLength={tableHead.length} isLoading={isLoading} />
            ) : (
              <>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={filteredUsers.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow hover key={index} tabIndex={-1}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.nim}</TableCell>
                      <TableCell align="left">{row?.graduate_year ?? '-'}</TableCell>
                      {row.results.map((cplRow) => (
                        <TableCell
                          key={cplRow.id}
                          align="left"
                          sx={{ color: cplRow.flag ? 'success.dark' : 'error.main' }}
                        >
                          {cplRow.grade}
                        </TableCell>
                      ))}

                      <TableCell align="left">
                        {checkStatus(row.results) ? (
                          <Chip color="success" label="Terpenuhi" size="small" sx={{ fontSize: 11, color: '#fff' }} />
                        ) : (
                          <Chip color="error" label="Tidak Terpenuhi" size="small" sx={{ fontSize: 11 }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={2} />
                    </TableRow>
                  )}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.student.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* jika hasil pencarian tidak ditemukan */}
                {isNotFound && data.student.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ditemukan hasil pencarian &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Periksa kembali kata kunci yang anda masukkan.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Box sx={{ m: 3 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          *Keterangan:
        </Typography>
        <Typography variant="body2">
          Tabel diatas merupakan perolehan nilai CPL setiap mahasiswa untuk keseluruhan mata kuliah (yang telah diambil
          dan yang belum diambil)
        </Typography>

        {!isLoading &&
          data.plo.map((plo) => (
            <Grid key={plo.id} container sx={{ marginTop: 1 }}>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2" sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                  {plo.code}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body2">{plo.outcome}</Typography>
              </Grid>
            </Grid>
          ))}
      </Box>
    </>
  );
}
