import { useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';

ModalEdit.prototype = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
};

export default function ModalEdit({ data, open, isSubmit, onUpdateData, onClose }) {
  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setErrors({});
    setTouched({
      learning_strategy: false,
      learning_management: false,
      participant: false,
      class_observation: false,
      constraint: false,
      improvement_recomendation: false,
    });
  };

  /**
   ** Handle data
   */
  const handleUpdateData = async () => {
    await onUpdateData(values);
  };

  useEffect(() => {
    setValues({
      learning_strategy: data.learning_strategy ?? '',
      learning_management: data.learning_management ?? '',
      participant: data.participant ?? '',
      class_observation: data.class_observation ?? '',
      constraint: data.constraint ?? '',
      improvement_recomendation: data.improvement_recomendation ?? '',
    });
  }, [data]);

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      learning_strategy: '',
      learning_management: '',
      participant: '',
      class_observation: '',
      constraint: '',
      improvement_recomendation: '',
    },
    validationSchema: yup.object().shape({
      learning_strategy: yup.string().required('Strategi pembelajaran tidak boleh kosong'),
      learning_management: yup.string().required('Manajemen pembelajaran tidak boleh kosong'),
      participant: yup.string().required('Peserta kelas tidak boleh kosong'),
      class_observation: yup.string().required('Observasi kelas tidak boleh kosong'),
      constraint: yup.string().nullable(),
      improvement_recomendation: yup.string().nullable(),
    }),
    onSubmit: handleUpdateData,
  });

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Edit Portofolio'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <InputLabel sx={{ color: '#000' }} htmlFor={'learning_strategy'}>
            {'Strategi Pembelajaran '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="learning_strategy"
            name="learning_strategy"
            label="Strategi Pembelajaran"
            value={values.learning_strategy}
            fullWidth
            rows={4}
            onChange={handleChange}
            error={Boolean(errors.learning_strategy) && Boolean(touched.learning_strategy)}
            helperText={
              Boolean(errors.learning_strategy) && Boolean(touched.learning_strategy) ? errors.learning_strategy : ''
            }
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'learning_management'}>
            {'Pengelolaan Pembelajaran '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="learning_management"
            name="learning_management"
            label="Pengelolaan Pembelajaran"
            value={values.learning_management}
            fullWidth
            rows={4}
            onChange={handleChange}
            error={Boolean(errors.learning_management) && Boolean(touched.learning_management)}
            helperText={
              Boolean(errors.learning_management) && Boolean(touched.learning_management)
                ? errors.learning_management
                : ''
            }
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'participant'}>
            {'Peserta Kelas '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="participant"
            name="participant"
            label="Peserta Kelas"
            value={values.participant}
            fullWidth
            rows={4}
            onChange={handleChange}
            error={Boolean(errors.participant) && Boolean(touched.participant)}
            helperText={Boolean(errors.participant) && Boolean(touched.participant) ? errors.participant : ''}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'class_observation'}>
            {'Pengamatan Kelas '}
            <span style={{ color: '#FF4842' }}>*</span>
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="class_observation"
            name="class_observation"
            label="Pengamatan Kelas"
            value={values.class_observation}
            fullWidth
            rows={4}
            onChange={handleChange}
            error={Boolean(errors.class_observation) && Boolean(touched.class_observation)}
            helperText={
              Boolean(errors.class_observation) && Boolean(touched.class_observation) ? errors.class_observation : ''
            }
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'constraint'}>
            Kendala
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="constraint"
            name="constraint"
            label="Kendala"
            value={values.constraint}
            fullWidth
            rows={4}
            onChange={handleChange}
          />

          <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'improvement_recomendation'}>
            Rekomendasi Perbaikan
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="improvement_recomendation"
            name="improvement_recomendation"
            label="Rekomendasi Perbaikan"
            value={values.improvement_recomendation}
            fullWidth
            rows={4}
            onChange={handleChange}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
