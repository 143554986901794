import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Box, Container, Typography, Dialog } from '@mui/material';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
import { ItemData } from '../../components/item-data';
// sections
import { MappingTable } from '../../sections/rps';
import { AttendancePercentageTable, EvaluationClassTable, ResultStudyTable } from '../../sections/portofolio';
// utils
import GatewayHttpService from '../../services/GatewayHttpService';
import Menu from './Menu';

export default function MonevPortofolioPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({});
  const [groupBy, setGroupBy] = useState({
    cpl: {},
    indicator: {},
  });
  const [pdfUrl, setPdfUrl] = useState('');

  /**
   ** Handle Component
   */
  const handleClosePdf = () => {
    setOpenPdf(false);
    window.URL.revokeObjectURL(pdfUrl);
    setPdfUrl('');
  };

  /**
   ** Handle data
   */
  const groupCpmk = (coursePlanLos) => {
    setGroupBy(() => ({
      cpl: Object.groupBy(coursePlanLos, (lo) => lo.curriculum_plo_code),
      indicator: Object.groupBy(coursePlanLos, (lo) => lo.curriculum_indicator_code),
    }));
  };

  /**
   ** Handle calling API
   */
  const getData = async () => {
    setIsLoading(true);
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/portofolio`
    );

    if (response.data.data.course_plan_los) {
      groupCpmk(response.data.data.course_plan_los);
    }

    setData(response.data.data);
    setIsLoading(false);
  };

  const print = async () => {
    setIsPrint(true);
    const response = await GatewayHttpService.secureGetFile(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/portofolio/print`
    );

    setPdfUrl(window.URL.createObjectURL(response.data));
    setIsPrint(false);
    setOpenPdf(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Portofolio" />

        <Menu isPrint={isPrint} onPrint={print} />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card sx={{ padding: 3 }}>
            {/**
             * Data Mata Kuliah
             */}
            <ItemData itemLabel="Kode" itemValue={data.code} sx={{ marginTop: 0 }} />
            <ItemData itemLabel="Nama" itemValue={data.name} />
            <ItemData itemLabel="Bobot" itemValue={`${data.credit} SKS`} />
            <ItemData itemLabel="Semester" itemValue={data.semester} />
            <ItemData itemLabel="Deskripsi" itemValue={data.description} />
            <ItemData itemLabel="Dosen" itemValue={data.lecturers} objectKey="id" objectValue="name" />
            <ItemData itemLabel="Jumlah Mahasiswa" itemValue={data.number_of_students} />
            <ItemData itemLabel="Tahun Akademik" itemValue={data.academic_year} />

            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/**
             * Data CPL - CPMK
             */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 1 : CPL - CPMK</Typography>
            </Box>
            <MappingTable data={data.course_plan_los} groupBy={groupBy} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/**
             * Data Hasil Belajar
             */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 2 : Hasil Belajar</Typography>
            </Box>
            <ResultStudyTable
              coursePlanLos={data.course_plan_los}
              learningResults={data.learning_results}
              notPasses={data.not_pass}
              groupBy={groupBy}
            />

            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/**
             * Data Persentase Kehadiran
             */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 3 : Persentase Kehadiran</Typography>
            </Box>
            <AttendancePercentageTable
              lecturer={data.attendance_percentages.lecturer}
              student={data.attendance_percentages.student}
            />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/**
             * Data Portofolio
             */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 4 : Evaluasi Kelas</Typography>
            </Box>
            <EvaluationClassTable data={data} />
          </Card>
        )}
      </Container>

      {/**
       * Popup viewer pdf
       */}
      <Dialog id="dialog-pdf" fullWidth maxWidth={'lg'} open={openPdf} onClose={handleClosePdf}>
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="Rencana Pembelajaran Semester (RPS).pdf"
          style={{ border: 'none' }}
        />
      </Dialog>
    </>
  );
}
