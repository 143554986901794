// @mui icons
import { Dashboard, Apartment, LibraryBooks, Troubleshoot, LocalLibrary, AccountTree } from '@mui/icons-material';

// component

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'beranda',
    path: '/beranda',
    icon: <Dashboard />,
    permissions: ['course_plans_access', 'course_plans_manage'],
  },
  {
    title: 'profil program studi',
    path: '/profil-prodi',
    icon: <Apartment />,
    permissions: ['curricula_access', 'curricula_manage'],
  },
  {
    title: 'capaian lulusan',
    path: '/capaian-lulusan',
    icon: <LocalLibrary />,
    permissions: ['curricula_access', 'curricula_manage'],
  },
  {
    title: 'indikator MK',
    path: '/indikator',
    icon: <LibraryBooks />,
    permissions: ['curricula_access', 'curricula_manage'],
  },
  {
    title: 'pemetaan Cpl-Ik-Mk',
    path: '/cpl-indikator-mk',
    icon: <AccountTree />,
    permissions: ['curricula_access', 'curricula_manage'],
  },
  {
    title: 'monev perkuliahan',
    path: '/monev-kuliah',
    icon: <Troubleshoot />,
    permissions: ['monev_access'],
  },
  {
    title: 'monev CPL',
    path: '/monev-capaian',
    icon: <Troubleshoot />,
    permissions: ['monev_access'],
  },
];

export default navConfig;
