import { useEffect, useState } from 'react';
// @mui
import { Card, Container } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
import ConfirmDelete from '../../components/modal/ConfirmDelete';
// sections
import { CPLTable } from '../../sections/cpl';
import Menu from './Menu';
import ModalAction from './ModalAction';
import ModalInsert from './ModalInsert';
import ModalUpdate from './ModalUpdate';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function CapaianLulusanPage() {
  /**
   ** Component variable
   */
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openInsert, setOpenInsert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAction, setOpenAction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [reload, setReload] = useState(0);

  /**
   ** Data variable
   */
  const [data, setData] = useState({
    curriculum: [],
    plo: [],
  });
  const [row, setRow] = useState(null);
  const [curriculum, setCurriculum] = useState(0);
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenAction = (event, row) => {
    setRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setOpenAction(null);
    setRow(null);
  };

  const handleOpenInsert = () => {
    setOpenInsert(true);
  };

  const handleCloseInsert = () => {
    setOpenInsert(false);
  };

  const handleOpenUpdate = () => {
    setOpenUpdate(true);
    setOpenAction(null);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setRow(null);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
    setOpenAction(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setRow(null);
  };

  /**
   ** Handle data
   */
  const changeCurriculum = (event) => {
    setCurriculum(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getCurriculum = async () => {
    const response = await GatewayHttpService.secureGet(`${process.env.REACT_APP_BACKEND_HOST}/api/curriculum`);
    setData((data) => ({ ...data, curriculum: response.data.data }));
    setIsLoading(false);
  };

  const getData = async (index = 0) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/curriculum-plo?curriculumId=${data.curriculum[index].id}`
    );
    setData((data) => ({ ...data, plo: response.data.data }));
  };

  const insertData = async (values) => {
    setIsSubmit(true);
    const response = await GatewayHttpService.securePost(
      `${process.env.REACT_APP_BACKEND_HOST}/api/curriculum-plo`,
      values
    );

    handleCloseInsert();
    setIsSubmit(false);

    if (response.status === 201) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setReload((reload) => reload + 1);
    } else if (response.status === 400) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const updateData = async (values) => {
    setIsSubmit(true);
    const response = await GatewayHttpService.securePut(
      `${process.env.REACT_APP_BACKEND_HOST}/api/curriculum-plo/${row.id}`,
      values
    );

    handleCloseUpdate();
    setIsSubmit(false);

    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setReload((reload) => reload + 1);
    } else if (response.status === 400) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const deleteData = async () => {
    setIsSubmit(true);
    const response = await GatewayHttpService.secureDelete(
      `${process.env.REACT_APP_BACKEND_HOST}/api/curriculum-plo/${row.id}`
    );

    handleCloseConfirm();
    setIsSubmit(false);

    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setReload((reload) => reload + 1);
    } else if (response.status === 409) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  useEffect(() => {
    getCurriculum();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Capaian Pembelajaran Lulusan" />

        {permissions.some((permission) => permission === 'curricula_manage') && (
          <Menu onOpenInsert={handleOpenInsert} />
        )}

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card>
            <CPLTable
              data={data}
              curriculum={curriculum}
              reload={reload}
              canModify={permissions.some((permission) => permission === 'curricula_manage')}
              onChangeCurriculum={changeCurriculum}
              onLoadData={getData}
              onOpenAction={handleOpenAction}
            />
          </Card>
        )}
      </Container>

      {/**
       * Popup Aksi
       */}
      <ModalAction
        open={openAction}
        onClose={handleCloseAction}
        onOpenUpdate={handleOpenUpdate}
        onOpenConfirm={handleOpenConfirm}
      />

      {/**
       * Popup Tambah Data
       */}
      <ModalInsert
        data={data}
        open={openInsert}
        isSubmit={isSubmit}
        onClose={handleCloseInsert}
        onInsertData={insertData}
      />

      {/**
       * Popup Update Data
       */}
      <ModalUpdate
        data={data}
        row={row}
        open={openUpdate}
        isSubmit={isSubmit}
        onClose={handleCloseUpdate}
        onUpdateData={updateData}
      />

      {/* modal confirm hapus data */}
      <ConfirmDelete open={openConfirm} isSubmit={isSubmit} onClose={handleCloseConfirm} onDeleteData={deleteData} />
    </>
  );
}
