import PropTypes from 'prop-types';
// @mui
import { Table, TableContainer, TableCell, TableRow, TableBody, Grid, Paper, Typography } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'curriculum_plo_code', label: 'Kode CPL', alignRight: false },
  { id: 'curriculum_indicator_code', label: 'Kode Indikator', alignRight: false },
  { id: 'course_plan_lo_code', label: 'Kode CPMK', alignRight: false },
  { id: 'max_grade', label: 'Bobot Maksimal', alignRight: false },
  { id: 'grade', label: 'Bobot Rata-rata Indikator', alignRight: false },
  { id: 'percentage', label: 'Persentase Capaian', alignRight: false },
];

ResultStudyTable.prototype = {
  coursePlanLos: PropTypes.array.isRequired,
  learningResults: PropTypes.array.isRequired,
  notPasses: PropTypes.array.isRequired,
  groupBy: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------

export default function ResultStudyTable({ notPasses, coursePlanLos, learningResults, groupBy }) {
  const filterByIndicatorCode = (code, results) => results.filter((item) => code === item.indicator_code)[0];

  const averageLearningResults = (key, results) =>
    parseFloat(results.reduce((sum, item) => sum + parseFloat(item[key]), 0) / results.length).toFixed(2);

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, mb: 1 }}>
          <Table>
            <TableListHead headLabel={TABLE_HEAD} rowCount={coursePlanLos.length} />
            <TableBody>
              {coursePlanLos.map((row, index, elements) => (
                <TableRow hover key={index} tabIndex={-1} sx={{ borderBottom: 2 }}>
                  {row.curriculum_plo_code !== elements[index - 1]?.curriculum_plo_code && (
                    <TableCell align="left" rowSpan={groupBy.cpl[row.curriculum_plo_code].length}>
                      {row.curriculum_plo_code}
                    </TableCell>
                  )}

                  {row.curriculum_indicator_code !== elements[index - 1]?.curriculum_indicator_code && (
                    <TableCell align="left" rowSpan={groupBy.indicator[row.curriculum_indicator_code].length}>
                      {row.curriculum_indicator_code}
                    </TableCell>
                  )}

                  <TableCell align="left">{row.course_plan_lo_code}</TableCell>

                  {row.curriculum_indicator_code !== elements[index - 1]?.curriculum_indicator_code && (
                    <>
                      <TableCell align="left" rowSpan={groupBy.indicator[row.curriculum_indicator_code].length}>
                        {filterByIndicatorCode(row.curriculum_indicator_code, learningResults).max_weight}
                      </TableCell>

                      <TableCell align="left" rowSpan={groupBy.indicator[row.curriculum_indicator_code].length}>
                        {filterByIndicatorCode(row.curriculum_indicator_code, learningResults).avg_weight}
                      </TableCell>

                      <TableCell align="left" rowSpan={groupBy.indicator[row.curriculum_indicator_code].length}>
                        {filterByIndicatorCode(row.curriculum_indicator_code, learningResults).percentage}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}

              {coursePlanLos.length && (
                <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
                  <TableCell align="left" colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Rata-Rata
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                    {averageLearningResults('avg_weight', learningResults)}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                    {averageLearningResults('percentage', learningResults)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            {/* jika data tidak ditemukan */}
            {!coursePlanLos.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Data tidak ditemukan
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      {/* jika ada yang tidak lulus */}
      {!Array.isArray(notPasses) && (
        <>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            *Keterangan
          </Typography>
          <Typography variant="body2">Mahasiswa berikut dinyatakan tidak lulus pada : </Typography>
          {Object.keys(notPasses).map((key) => (
            <Grid key={key} container sx={{ marginTop: 1 }}>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2" sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>{`${key}`}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body2">
                  {notPasses[key].map((row, index, elements) => {
                    const comma = index !== elements.length - 1 ? ', ' : '';
                    return `${row}${comma}`;
                  })}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </>
  );
}
