import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// middleware
import ProtectedRoute from './middleware/ProtectedRoute';
// pages
import ProtectedAccess from './middleware/ProtectedAccess';
import Page403 from './pages/Page403';
import Page404 from './pages/Page404';
import LoginPage from './pages/LoginPage';
import ProfilProdiPage from './pages/profil-prodi/ProfilProdiPage';
import CapaianLulusanPage from './pages/cpl/CapaianLulusanPage';
import IndikatorPage from './pages/indikator/IndikatorPage';
import MappingCplIkMkPage from './pages/mapping/CPLIKMKPage';
import ProfilPage from './pages/auth/ProfilPage';
import BerandaPage from './pages/beranda/BerandaPage';
import RPSPage from './pages/rps/RPSPage';
import PortofolioPage from './pages/portofolio/PortofolioPage';
import BAPPage from './pages/bap/BAPPage';
import RekapNilaiPage from './pages/rekap-nilai/RekapNilaiPage';
import NilaiPage from './pages/nilai/NilaiPage';
import MonevMKPage from './pages/monev-mk/MonevMKPage';
import MonevRPSPage from './pages/monev-rps/MonevRPSPage';
import MonevPortofolioPage from './pages/monev-portofolio/MonevPortofolioPage';
import MonevBAPPage from './pages/monev-bap/MonevBAPPage';
import MonevRekapNilaiPage from './pages/monev-rekap-nilai/MonevRekapNilaiPage';
import MonevCapaianPage from './pages/monev-cpl/MonevCapaianPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute replacePage>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'beranda',
          element: (
            <ProtectedAccess permissions={['course_plans_access', 'course_plans_manage']} replacePage>
              <BerandaPage />
            </ProtectedAccess>
          ),
          index: true,
        },
        {
          path: 'profil-prodi',
          element: (
            <ProtectedAccess permissions={['curricula_access', 'curricula_manage']} replacePage>
              <ProfilProdiPage />
            </ProtectedAccess>
          ),
        },
        {
          path: 'capaian-lulusan',
          element: (
            <ProtectedAccess permissions={['curricula_access', 'curricula_manage']} replacePage>
              <CapaianLulusanPage />
            </ProtectedAccess>
          ),
        },
        {
          path: 'indikator',
          element: (
            <ProtectedAccess permissions={['curricula_access', 'curricula_manage']} replacePage>
              <IndikatorPage />
            </ProtectedAccess>
          ),
        },
        {
          path: 'cpl-indikator-mk',
          element: (
            <ProtectedAccess permissions={['curricula_access', 'curricula_manage']} replacePage>
              <MappingCplIkMkPage />
            </ProtectedAccess>
          ),
        },
        {
          path: 'mata-kuliah',
          children: [
            {
              path: ':id',
              element: (
                <ProtectedAccess permissions={['course_plans_access', 'course_plans_manage']} replacePage>
                  <RPSPage />
                </ProtectedAccess>
              ),
            },
            {
              path: ':id/portofolio',
              element: (
                <ProtectedAccess permissions={['course_plans_access', 'course_plans_manage']} replacePage>
                  <PortofolioPage />
                </ProtectedAccess>
              ),
            },
            {
              path: ':id/nilai',
              element: (
                <ProtectedAccess permissions={['classes_access', 'classes_manage']} replacePage>
                  <NilaiPage />
                </ProtectedAccess>
              ),
            },
            {
              path: ':id/bap',
              element: (
                <ProtectedAccess permissions={['bap_access', 'bap_manage']} replacePage>
                  <BAPPage />
                </ProtectedAccess>
              ),
            },
            {
              path: ':id/rekap-nilai',
              element: (
                <ProtectedAccess permissions={['classes_access', 'classes_manage']} replacePage>
                  <RekapNilaiPage />
                </ProtectedAccess>
              ),
            },
          ],
        },
        {
          path: 'monev-kuliah',
          element: <ProtectedAccess permissions={['monev_access']} replacePage />,
          children: [
            { path: '', element: <MonevMKPage /> },
            { path: 'mata-kuliah/:id', element: <MonevRPSPage /> },
            { path: 'mata-kuliah/:id/portofolio', element: <MonevPortofolioPage /> },
            { path: 'mata-kuliah/:id/bap', element: <MonevBAPPage /> },
            { path: 'mata-kuliah/:id/rekap-nilai', element: <MonevRekapNilaiPage /> },
          ],
        },
        {
          path: 'monev-capaian',
          element: (
            <ProtectedAccess permissions={['monev_access']} replacePage>
              <MonevCapaianPage />
            </ProtectedAccess>
          ),
        },
        {
          path: 'profil',
          element: <ProfilPage />,
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
      index: true,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/beranda" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
