import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Box, Avatar } from '@mui/material';

CardPhoto.prototype = {
  imageUrl: PropTypes.string.isRequired,
};

export default function CardPhoto({ imageUrl }) {
  return (
    <Card sx={{ padding: 3 }}>
      <Typography variant="h6" mb={5}>
        Foto
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Avatar alt="Foto profil" src={imageUrl} sx={{ width: 160, height: 160 }} />
      </Box>
    </Card>
  );
}
