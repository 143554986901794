import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Button } from '@mui/material';
import { ClockLoader } from 'react-spinners';
// components
import Iconify from '../../components/iconify';

Menu.prototype = {
  data: PropTypes.object.isRequired,
  isPrint: PropTypes.bool.isRequired,
  onPrint: PropTypes.func.isRequired,
  onOpenVerify: PropTypes.func.isRequired,
  printProps: PropTypes.any.isRequired,
};

export default function Menu({ data, isPrint, onPrint, onOpenVerify, printProps }) {
  /**
   ** Component variable
   */
  const navigate = useNavigate();

  /**
   ** Data variable
   */
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  return (
    <Card sx={{ padding: 3, marginBottom: 4 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:arrow-circle-left-fill" />}
          onClick={() => navigate('/monev-kuliah')}
        >
          Kembali
        </Button>

        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={1}>
          <Button
            variant="contained"
            startIcon={
              isPrint ? (
                <ClockLoader color={'#308fe8'} size={18} loading={isPrint} />
              ) : (
                <Iconify icon="eva:printer-outline" />
              )
            }
            onClick={onPrint}
            sx={{ marginRight: 1 }}
            {...printProps}
          >
            Cetak
          </Button>

          {permissions.some((permission) => permission === 'bap_verify') && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="lets-icons:check-fill" />}
              onClick={onOpenVerify}
              disabled={!data.class.class_courses?.length}
              sx={{ mr: 1 }}
            >
              Verifikasi
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
