import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
// @mui
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { Stack, IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { PulseLoader } from 'react-spinners';
// components
import Iconify from '../../../components/iconify';
// utils

// ----------------------------------------------------------------------

export default function LoginForm() {
  /**
   ** Component variable
   */
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const access = {
    authorization: JSON.parse(localStorage.getItem('authorization')),
    pesan: sessionStorage.getItem('pesan'),
  };

  /**
   ** Data variable
   */

  /**
   ** Handle calling API
   */
  const handleLogin = async () => {
    try {
      setIsSubmit(true);
      setIsLoading(true);

      // call api
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/api/login`, values, {
        headers: { 'Content-Type': 'application/json' },
      });

      setIsLoading(false);

      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      const permissions = [];
      response.data.data.permissions.forEach((permission) => permissions.push(permission.name));

      setTimeout(() => {
        setIsSubmit(false);
        localStorage.setItem('authorization', JSON.stringify(response.data.data.authorization));
        localStorage.setItem('user', JSON.stringify(response.data.data.profile));
        localStorage.setItem('permissions', JSON.stringify(permissions));
        navigate('/beranda', { replace: true });
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      setIsSubmit(false);

      enqueueSnackbar(error?.response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  /**
   ** Validation data
   */
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().required('Email tidak boleh kosong').email('Email tidak valid'),
      password: yup.string().required('Password tidak boleh kosong'),
    }),
    onSubmit: handleLogin,
  });

  useEffect(() => {
    if (access.authorization && Date.now() < access.authorization?.expires_at) {
      navigate('/beranda', { replace: true });
    }

    if (access?.pesan) {
      enqueueSnackbar(access.pesan, {
        variant: 'warning',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      sessionStorage.removeItem('pesan');
    }
  }, []);

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Stack spacing={3} sx={{ my: 5 }}>
        <TextField
          name="email"
          label="Email"
          type="email"
          autoComplete="email"
          onChange={handleChange}
          error={Boolean(errors.email) && Boolean(touched.email)}
          helperText={Boolean(errors.email) && Boolean(touched.email) ? errors.email : ''}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={handleChange}
          autoComplete="current-password"
          error={Boolean(errors.password) && Boolean(touched.password)}
          helperText={Boolean(errors.password) && Boolean(touched.password) ? errors.password : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Button disabled={isSubmit} fullWidth size="large" type="submit" variant="contained">
        {isLoading ? <PulseLoader color="#308fe8" size={10} /> : 'Login'}
      </Button>

      {/* notifikasi */}
      <SnackbarProvider maxSnack={3} />
    </form>
  );
}
