import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container, Dialog } from '@mui/material';
import dayjs from 'dayjs';
// components
import { enqueueSnackbar } from 'notistack';
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { BAPTable, DetailMeeting } from '../../sections/bap';
import Menu from './Menu';
import ModalAction from './ModalAction';
import ModalEdit from './ModalEdit';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function BAPPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAction, setOpenAction] = useState(null);
  const [openDetail, setOpenDetail] = useState(null);
  const [reload, setReload] = useState(0);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({
    class: {},
    bap: [],
    room: [],
  });
  const [row, setRow] = useState(null);
  const [kelas, setKelas] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenAction = (event, row) => {
    setRow(row);
    setOpenAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setOpenAction(null);
    setRow(null);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
    setOpenAction(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setRow(null);
  };

  const handleOpenDetail = (event, row) => {
    setRow(row);
    setOpenDetail(event.currentTarget);
  };

  const handleCloseDetail = () => {
    setOpenDetail(null);
    setRow(null);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
    window.URL.revokeObjectURL(pdfUrl);
    setPdfUrl('');
  };

  /**
   ** Handle data
   */
  const changeKelas = (event) => {
    setKelas(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getClass = async () => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/class?assessment=true`
    );
    setData((data) => ({ ...data, class: response.data.data }));
    setIsLoading(false);
  };

  const getBap = async (kelas = 0) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/bap`
    );
    setData((data) => ({ ...data, bap: response.data.data }));
  };

  const getRoom = async () => {
    const response = await GatewayHttpService.secureGet(`${process.env.REACT_APP_BACKEND_HOST}/api/room`);
    setData((data) => ({ ...data, room: response.data.data }));
  };

  const storeBap = async (values) => {
    setIsSubmit(true);

    const newData = {
      meet_no: row.week,
      class_id: data.class.class_courses[kelas].id,
      course_plan_detail_id: row.course_plan_detail_id,
      material_real: values.material_real,
      assessment_real: values.assessment_real.join(', '),
      method: values.method,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      time_start_at: dayjs(values.time_start_at).format('HH:mm'),
      time_end_at: dayjs(values.time_end_at).format('HH:mm'),
    };

    if (values.method === 1) {
      newData.room_id = values.room.id;
    } else if (values.method === 0) {
      newData.ol_platform = values.ol_platform;
      newData.ol_links = values.ol_links;
    }

    const response = await GatewayHttpService.securePost(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-meeting`,
      newData
    );

    handleCloseEdit();
    setIsSubmit(false);

    if (response.status === 201) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setReload((reload) => reload + 1);
    }
  };

  const print = async () => {
    setIsPrint(true);
    const response = await GatewayHttpService.secureGetFile(
      `${process.env.REACT_APP_BACKEND_HOST}/api/class-course/${data.class.class_courses[kelas].id}/bap/print`
    );

    setPdfUrl(window.URL.createObjectURL(response?.data));
    setIsPrint(false);
    setOpenPdf(true);
  };

  useEffect(() => {
    getClass();
    getRoom();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Berita Acara Perkuliahan" />

        <Menu
          isPrint={isPrint}
          onPrint={print}
          printProps={{ disabled: isPrint || !data.class.class_courses?.length }}
        />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card>
            {/**
             * Data BAP
             */}
            <BAPTable
              data={data}
              kelas={kelas}
              reload={reload}
              canModify={permissions.some((permission) => permission === 'bap_manage')}
              onChangeKelas={changeKelas}
              onLoadData={getBap}
              onOpenAction={handleOpenAction}
              onOpenDetail={handleOpenDetail}
            />
          </Card>
        )}
      </Container>

      {/**
       * Popup Aksi
       */}
      <ModalAction open={openAction} onClose={handleCloseAction} onOpenEdit={handleOpenEdit} />

      {/**
       * Popup detail pertemuan
       */}
      <DetailMeeting row={row} open={openDetail} onClose={handleCloseDetail} />

      {/**
       * Modal Update Bap
       */}
      <ModalEdit
        data={data}
        row={row}
        open={openEdit}
        isSubmit={isSubmit}
        onClose={handleCloseEdit}
        onUpdateData={storeBap}
      />

      {/**
       * Popup viewer pdf
       */}
      <Dialog id="dialog-pdf" fullWidth maxWidth={'lg'} open={openPdf} onClose={handleClosePdf}>
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="Berita Acara Perkuliahan (BAP).pdf"
          style={{ border: 'none' }}
        />
      </Dialog>
    </>
  );
}
