import PropTypes from 'prop-types';
// @mui
import {
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Select,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormHelperText,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../../sections/table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'course_plan_lo_code', label: 'Kode', alignRight: false },
  { id: 'course_plan_lo_name', label: 'CPMK', alignRight: false },
  { id: 'grade', label: 'Nilai', alignRight: false },
];

ModalInput.prototype = {
  data: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInputData: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------

export default function ModalInput({ data, row, open, isSubmit, onClose, onInputData }) {
  /**
   ** Handle component
   */
  const handleClose = () => {
    onClose();
    setValues({
      course_plan_assessment_id: '',
      course_plan_los: [],
    });
    setErrors({});
    setTouched({
      course_plan_assessment_id: false,
      course_plan_los: false,
    });
  };

  /**
   ** Handle data
   */
  const changeCpa = (event) => {
    setFieldValue('course_plan_assessment_id', parseInt(event.target.value, 10));
    setFieldValue(
      'course_plan_los',
      row.assessments.filter((item) => item.course_plan_assessment_id === parseInt(event.target.value, 10))
    );
    setErrors({});
    setTouched({
      course_plan_assessment_id: false,
      course_plan_los: false,
    });
  };

  const handleInputData = async () => {
    await onInputData(values);
    handleClose();
  };

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      course_plan_assessment_id: '',
      course_plan_los: [],
    },
    validationSchema: yup.object().shape({
      course_plan_assessment_id: yup.number().required('Teknik penilaian tidak boleh kosong'),
      course_plan_los: yup.array().of(
        yup.object().shape({
          course_plan_lo_id: yup.number().when('course_plan_assessment_id', {
            is: (value) => value !== '',
            then: (schema) => schema.required('Cpmk tidak boleh kosong'),
            otherwise: (schema) => schema.nullable(),
          }),
          grade: yup.number().when('course_plan_assessment_id', {
            is: (value) => value !== '',
            then: (schema) =>
              schema
                .required('Nilai tidak boleh kosong')
                .min(0, 'Nilai tidak boleh kurang dari 0')
                .max(100, 'Nilai tidak boleh lebih dari 100'),
            otherwise: (schema) => schema.nullable(),
          }),
        })
      ),
    }),
    onSubmit: handleInputData,
  });

  return (
    <Dialog fullWidth maxWidth={'lg'} open={open} onClose={handleClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>Input Nilai</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography mb={1}>
            {'Teknik penilaian '}
            <span style={{ color: '#FF4842' }}>*</span>
          </Typography>
          <FormControl sx={{ minWidth: 300, mb: 3 }}>
            <InputLabel id="label_list_cpa">Teknik penilaian</InputLabel>
            <Select
              labelId="label_list_cpa"
              id="list_cpa"
              name="course_plan_assessment_id"
              defaultValue=""
              label="Teknik penilaian"
              onChange={changeCpa}
              error={Boolean(errors.course_plan_assessment_id) && Boolean(touched.course_plan_assessment_id)}
            >
              {data.class.course_plan_assessments?.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>

            {Boolean(errors.course_plan_assessment_id) && Boolean(touched.course_plan_assessment_id) && (
              <FormHelperText error>{errors.course_plan_assessment_id}</FormHelperText>
            )}
          </FormControl>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead headLabel={TABLE_HEAD} rowCount={values.course_plan_los.length} />

                <TableBody>
                  {values.course_plan_los.map((row, index) => (
                    <TableRow hover key={row.course_plan_lo_id} tabIndex={-1}>
                      <TableCell align="left">{row.course_plan_lo_code}</TableCell>
                      <TableCell align="left">{row.course_plan_lo_name}</TableCell>
                      <TableCell align="left" sx={{ width: 200 }}>
                        <TextField
                          margin="dense"
                          id={`grade_${row.course_plan_lo_id}`}
                          name={`course_plan_los.[${index}].grade`}
                          label="Nilai"
                          type="text"
                          value={row.grade}
                          error={
                            Boolean(errors.course_plan_los?.[index]?.grade) &&
                            Boolean(touched.course_plan_los?.[index]?.grade)
                          }
                          helperText={
                            Boolean(errors.course_plan_los?.[index]?.grade) &&
                            Boolean(touched.course_plan_los?.[index]?.grade)
                              ? errors.course_plan_los?.[index]?.grade
                              : ''
                          }
                          onChange={(event) => {
                            const value = event.target.value.match(/\d+([.]\d{0,2})?/g);
                            setFieldValue(`course_plan_los.[${index}].grade`, value ? value[0] : '');
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {/* jika data tidak ada */}
                {!values.course_plan_los.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
