import { useEffect, useState } from 'react';
// @mui
import { Card, Container } from '@mui/material';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
// sections
import { MonevCplTable } from '../../sections/monev-cpl';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function MonevCapaianPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Data variable
   */
  const [data, setData] = useState({
    year: [],
    plo: [],
    student: [],
  });
  const [year, setYear] = useState(0);

  /**
   ** Handle data
   */
  const changeYear = (event) => {
    setYear(parseInt(event.target.value, 10));
  };

  /**
   ** Handle calling API
   */
  const getYear = async () => {
    const response = await GatewayHttpService.secureGet(`${process.env.REACT_APP_BACKEND_HOST}/api/student/list-year`);

    setYear(response.data.data[response.data.data.length - 1]);
    setData((data) => ({ ...data, year: response.data.data }));
    setIsLoading(false);
  };

  const getStudent = async (yearData) => {
    const response = await GatewayHttpService.secureGet(
      `${process.env.REACT_APP_BACKEND_HOST}/api/student/monev?year=${yearData}`
    );
    setData((data) => ({ ...data, plo: response.data.data.curriculum_plos, student: response.data.data.students }));
  };

  useEffect(() => {
    getYear();
  }, []);

  return (
    <Container>
      <TitlePage title="Monitoring CPL Mahasiswa" />

      {isLoading ? (
        <LoadingCard isLoading={isLoading} />
      ) : (
        <Card>
          <MonevCplTable data={data} year={year} onChangeYear={changeYear} onLoadData={getStudent} />
        </Card>
      )}
    </Container>
  );
}
