import PropTypes from 'prop-types';
// @mui
import { Table, TableContainer, TableCell, TableRow, TableBody } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'point', label: 'Poin', alignRight: false },
  { id: 'description', label: 'Deskripsi', alignRight: false },
];

EvaluationClass.prototype = {
  data: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------

export default function EvaluationClass({ data }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={TABLE_HEAD} />
          <TableBody>
            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Strategi Pembelajaran</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.learning_strategy ?? '-'}</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Pengelolaan Pembelajaran</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.learning_management ?? '-'}</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Peserta Kelas</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.participant ?? '-'}</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Pengamatan Kelas</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.class_observation ?? '-'}</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Kendala</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.constraint ?? '-'}</TableCell>
            </TableRow>

            <TableRow hover tabIndex={-1} sx={{ borderBottom: 2 }}>
              <TableCell>Rekomendasi Perbaikan</TableCell>
              <TableCell sx={{ whiteSpace: 'pre-line' }}>{data.improvement_recomendation ?? '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
