import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Select,
  InputLabel,
  FormControl,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { LoadingTable } from '../../components/loading';
// sections
import { TableListHead, TableListToolbar } from '../table';
// utils
import { applySortFilter, getComparator } from '../../utils/sortCompare';

// ----------------------------------------------------------------------

IndikatorTable.prototype = {
  data: PropTypes.object.isRequired,
  curriculum: PropTypes.number.isRequired,
  reload: PropTypes.number.isRequired,
  canModify: PropTypes.bool,
  onChangeCurriculum: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
  onOpenAction: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function IndikatorTable({
  data,
  curriculum,
  reload,
  canModify = false,
  onChangeCurriculum,
  onLoadData,
  onOpenAction,
}) {
  /**
   ** Component variable
   */
  const [tableHead, setTableHead] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('code');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  /**
   ** Handle component, sort data, and pagination
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.indicator.length) : 0;
  const filteredUsers = applySortFilter(data.indicator, getComparator(order, orderBy), filterName, 'indicator');
  const isNotFound = !filteredUsers.length && !!filterName;

  const modifyHeader = () => {
    const modifiedHeaderTable = [
      { id: 'cpl_code', label: 'Kode CPL', alignRight: false },
      { id: 'code', label: 'Kode', alignRight: false },
      { id: 'indicator', label: 'Indikator Mata Kuliah', alignRight: false },
      { id: 'min_grade', label: 'Nilai minimal', alignRight: false },
    ];

    if (canModify) {
      modifiedHeaderTable.push({ id: '' });
    }

    setTableHead(modifiedHeaderTable);
  };

  /**
   ** Handle data
   */
  const handleChangeCurriculum = async (event) => {
    setPage(0);
    setIsLoading(true);
    onChangeCurriculum(event);

    await onLoadData(parseInt(event.target.value, 10));
    setIsLoading(false);
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    await onLoadData(curriculum);
    setIsLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, [reload]);

  useLayoutEffect(() => {
    modifyHeader();
  }, []);

  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }}>
        <Box ml={3} mt={{ xs: 3, sm: 0 }} sx={{ minWidth: 120 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="label_list_curriculum">Kurikulum</InputLabel>
            {Boolean(data.curriculum.length) && (
              <Select
                labelId="label_list_curriculum"
                id="list_curriculum"
                value={curriculum}
                label="Kurikulum"
                name="curriculum"
                onChange={handleChangeCurriculum}
              >
                {data.curriculum.map((row, index) => (
                  <MenuItem key={row.id} value={index}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>

        <TableListToolbar
          filterData={filterName}
          onFilterData={handleFilterByName}
          placeholder={'Indikator Mata Kuliah...'}
        />
      </Stack>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={filteredUsers.length}
              onRequestSort={handleRequestSort}
            />

            {isLoading ? (
              <LoadingTable headLength={tableHead.length} isLoading={isLoading} />
            ) : (
              <>
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row.id} tabIndex={-1}>
                      <TableCell align="left" scope="row">
                        {row.cpl_code}
                      </TableCell>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.indicator}</TableCell>
                      <TableCell align="left">{row.min_grade}</TableCell>
                      {canModify && (
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => onOpenAction(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={tableHead.length} />
                    </TableRow>
                  )}
                </TableBody>

                {/* jika data tidak ada */}
                {!data.indicator.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ pt: 4, pb: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {/* jika hasil pencarian tidak ditemukan */}
                {isNotFound && data.indicator.length > 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={tableHead.length} sx={{ py: 5 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Data tidak ditemukan
                          </Typography>

                          <Typography variant="body2">
                            Tidak ditemukan hasil pencarian &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Periksa kembali kata kunci yang anda masukkan.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
