// ----------------------------------------------------------------------

export default function ProtectedMenu(menu) {
  /**
   ** Data variable
   */
  const userPermissions = JSON.parse(localStorage.getItem('permissions'));

  /**
   ** Check authorization
   */
  const result = menu.filter((mn) => userPermissions.some((permission) => mn.permissions.includes(permission)));

  return result;
}
