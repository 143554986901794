import PropTypes from 'prop-types';
// @mui
import { Table, Typography, TableRow, TableBody, TableCell, TableContainer, Paper } from '@mui/material';
// components
import Scrollbar from '../../components/scrollbar';
// sections
import { TableListHead } from '../table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'media', label: 'Media', alignRight: false },
  { id: 'type', label: 'Tipe', alignRight: false },
];

CoursePlanMediaTable.prototype = {
  data: PropTypes.array.isRequired,
};

// ----------------------------------------------------------------------

export default function CoursePlanMediaTable({ data }) {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableListHead headLabel={TABLE_HEAD} rowCount={data.length} />
          <TableBody>
            {data.map((row) => (
              <TableRow hover key={row.id} tabIndex={-1} sx={{ borderBottom: 2 }}>
                <TableCell align="left">{row.media}</TableCell>
                <TableCell align="left">{row.type === 1 ? 'Perangkat Lunak' : 'Perangkat Keras'}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {/* jika data tidak ada */}
          {!data.length && (
            <TableBody>
              <TableRow sx={{ borderBottom: 2 }}>
                <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 5 }}>
                  <Paper
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" paragraph>
                      Data tidak ditemukan
                    </Typography>
                  </Paper>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
