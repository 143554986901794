import { useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Button, InputLabel, TextField, InputAdornment, IconButton } from '@mui/material';
import { ClockLoader } from 'react-spinners';
import * as yup from 'yup';
// components
import Iconify from '../../components/iconify';

CardPassword.prototype = {
  onUpdateData: PropTypes.func.isRequired,
};

export default function CardPassword({ onUpdateData }) {
  /**
   ** Component variable
   */
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  /**
   ** Handle data
   */
  const handleUpdateData = async () => {
    setIsSubmit(true);
    await onUpdateData(values);
    setValues({ password_current: '', password_new: '', password_confirm: '' });
    setErrors({});
    setTouched({ password_current: false, password_new: false, password_confirm: false });
    setIsSubmit(false);
  };

  /**
   ** Handle validation data
   */
  const { values, errors, touched, setValues, setErrors, setTouched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password_current: '',
      password_new: '',
      password_confirm: '',
    },
    validationSchema: yup.object().shape({
      password_current: yup.string().required('Password lama tidak boleh kosong'),
      password_new: yup
        .string()
        .required('Password baru tidak boleh kosong')
        .min(8, 'Password baru minimal 8 karakter'),
      password_confirm: yup
        .string()
        .required('Konfirmasi password tidak boleh kosong')
        .oneOf([yup.ref('password_new'), null], 'Password tidak cocok'),
    }),
    onSubmit: handleUpdateData,
  });

  return (
    <Card sx={{ padding: 3 }}>
      <Typography variant="h6" mb={5}>
        Password
      </Typography>

      <form method="POST" onSubmit={handleSubmit}>
        <InputLabel sx={{ color: '#000' }} htmlFor={'password_current'}>
          Password Lama
        </InputLabel>
        <TextField
          type={showPasswordCurrent ? 'text' : 'password'}
          margin="dense"
          id="password_current"
          name="password_current"
          label="Password Lama"
          autoComplete="off"
          fullWidth
          value={values.password_current}
          onChange={handleChange}
          error={Boolean(errors.password_current) && Boolean(touched.password_current)}
          helperText={
            Boolean(errors.password_current) && Boolean(touched.password_current) ? errors.password_current : ''
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordCurrent(!showPasswordCurrent)} edge="end">
                  <Iconify icon={showPasswordCurrent ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'password_new'}>
          Password Baru
        </InputLabel>
        <TextField
          type={showPasswordNew ? 'text' : 'password'}
          margin="dense"
          id="password_new"
          name="password_new"
          label="Password Baru"
          autoComplete="off"
          fullWidth
          value={values.password_new}
          onChange={handleChange}
          error={Boolean(errors.password_new) && Boolean(touched.password_new)}
          helperText={Boolean(errors.password_new) && Boolean(touched.password_new) ? errors.password_new : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordNew(!showPasswordNew)} edge="end">
                  <Iconify icon={showPasswordNew ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <InputLabel sx={{ color: '#000', marginTop: 3 }} htmlFor={'password_confirm'}>
          Konfirmasi Password
        </InputLabel>
        <TextField
          type={showPasswordConfirm ? 'text' : 'password'}
          margin="dense"
          id="password_confirm"
          name="password_confirm"
          label="Password Baru"
          autoComplete="off"
          fullWidth
          value={values.password_confirm}
          onChange={handleChange}
          error={Boolean(errors.password_confirm) && Boolean(touched.password_confirm)}
          helperText={
            Boolean(errors.password_confirm) && Boolean(touched.password_confirm) ? errors.password_confirm : ''
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} edge="end">
                  <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          type="submit"
          sx={{ marginTop: 3, float: 'right' }}
          disabled={isSubmit}
          startIcon={
            isSubmit ? (
              <ClockLoader color={'#308fe8'} size={18} loading={isSubmit} />
            ) : (
              <Iconify icon="mdi:content-save-outline" />
            )
          }
        >
          Simpan
        </Button>
      </form>
    </Card>
  );
}
