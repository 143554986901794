import PropTypes from 'prop-types';
// @mui
import {
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';

ModalVerify.prototype = {
  data: PropTypes.object.isRequired,
  kelas: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  isSubmit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onVerifyData: PropTypes.func.isRequired,
};

export default function ModalVerify({ data, kelas, open, isSubmit, onClose, onVerifyData }) {
  /**
   ** Handle data
   */
  const handleVerifyData = async () => {
    await onVerifyData(values);
  };

  useEffect(() => {
    setValues({
      meeting_nonconfirmity: data.class.class_courses?.[kelas]?.meeting_nonconfirmity ?? '',
      meeting_verified: Boolean(data.class.class_courses?.[kelas]?.meeting_verified ?? true),
    });
  }, [data.class]);

  /**
   ** Handle validation data
   */
  const { values, setValues, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      meeting_nonconfirmity: '',
      meeting_verified: true,
    },
    validationSchema: yup.object().shape({
      meeting_nonconfirmity: yup.string().nullable(),
      meeting_verified: yup.boolean().when('meeting_nonconfirmity', {
        is: (value) => value !== '',
        then: (schema) => schema.required('Verifikasi BAP tidak boleh kosong'),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    onSubmit: handleVerifyData,
  });

  return (
    <Dialog fullWidth maxWidth={'xs'} open={open} onClose={onClose}>
      <form method="POST" onSubmit={handleSubmit}>
        <DialogTitle>{'Verifikasi BAP'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <InputLabel sx={{ color: '#000' }} htmlFor={'learning_strategy'}>
            Temuan ketidaksesuaian
          </InputLabel>
          <TextField
            multiline
            margin="dense"
            id="meeting_nonconfirmity"
            name="meeting_nonconfirmity"
            label="Temuan Ketidaksesuaian"
            value={values.meeting_nonconfirmity}
            fullWidth
            rows={4}
            onChange={(event) => {
              values.meeting_verified = event.target.value === '';
              setFieldValue('meeting_nonconfirmity', event.target.value);
            }}
          />

          <FormGroup>
            <FormControlLabel disabled control={<Checkbox checked={values.meeting_verified} />} label="Verifikasi" />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Batal</Button>
          <Button disabled={isSubmit} type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
