import PropTypes from 'prop-types';
// @mui
import { Typography } from '@mui/material';

ItemDataVertical.prototype = {
  itemLabel: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
};

function ItemDataVertical({ itemLabel, itemValue }) {
  return (
    <>
      <Typography variant="subtitle1" mt={2}>
        {itemLabel}
      </Typography>
      <Typography>{itemValue ?? '-'}</Typography>
    </>
  );
}

export default ItemDataVertical;
