import PropTypes from 'prop-types';
// @mui
import { Popover, Typography } from '@mui/material';

DetailMeeting.prototype = {
  row: PropTypes.object.isRequired,
  open: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function DetailMeeting({ row, open, onClose }) {
  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 1,
          width: 150,
          wordWrap: 'break-word',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        },
      }}
    >
      {row?.room_id && (
        <>
          <Typography sx={{ fontWeight: 'bold' }}>Ruangan</Typography>
          <Typography>{row.room_name}</Typography>
        </>
      )}

      {row?.ol_platform && (
        <>
          <Typography sx={{ fontWeight: 'bold' }}>Media</Typography>
          <Typography>{row.ol_platform}</Typography>
        </>
      )}

      {row?.ol_links && (
        <>
          <Typography sx={{ fontWeight: 'bold' }}>Tautan</Typography>
          <Typography>{row.ol_links}</Typography>
        </>
      )}
    </Popover>
  );
}
