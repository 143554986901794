import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Grid } from '@mui/material';
// components
import { enqueueSnackbar } from 'notistack';
import TitlePage from '../../components/title-page';
// constant
import { defaultAvatar } from '../../utils/constant';
// sections
import CardPhoto from './CardPhoto';
import CardBio from './CardBio';
import CardPassword from './CardPassword';
// services
import GatewayHttpService from '../../services/GatewayHttpService';
import ModalUpdateProfil from './ModalUpdateProfil';

export default function ProfilPage() {
  /**
   ** Component variable
   */
  const navigate = useNavigate();
  const [openUpdate, setOpenUpdate] = useState(false);

  /**
   ** Data variable
   */
  const [imageUrl, setImageUrl] = useState('');
  let user = JSON.parse(localStorage.getItem('user'));

  /**
   ** Handle component, sort data, and pagination
   */
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const changeImageUrl = () => {
    if (user?.photo) {
      setImageUrl(`${process.env.REACT_APP_BACKEND_HOST}/storage/${user.photo}`);
    } else if (user?.gender) {
      setImageUrl(defaultAvatar[user.gender]);
    } else {
      setImageUrl('');
    }
  };

  /**
   ** Handle calling API
   */
  const updatePassword = async (values) => {
    const response = await GatewayHttpService.securePost(`${process.env.REACT_APP_BACKEND_HOST}/api/password`, values);

    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setTimeout(() => {
        localStorage.removeItem('authorization');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        navigate('/login', { replace: true });
      }, 3000);
    } else if (response.status === 403) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const updateProfile = async (values) => {
    const data = { ...values };

    // modify data
    if (!data.birthplace) {
      data.birthday = null;
    }
    data.phone = values?.phone ? `+62${values.phone}` : null;

    const response = await GatewayHttpService.securePostFormData(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v2/me`,
      data
    );

    handleCloseUpdate();

    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      user = { ...user, ...response.data.data };
      localStorage.setItem('user', JSON.stringify(user));

      changeImageUrl();
    } else if (response.status === 400) {
      enqueueSnackbar(response.data.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  useEffect(() => {
    changeImageUrl();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Profil" />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardPhoto imageUrl={imageUrl} />

            <CardBio user={user} onOpenUpdate={handleOpenUpdate} />
          </Grid>

          <Grid item xs={12} md={6}>
            <CardPassword onUpdateData={updatePassword} />
          </Grid>
        </Grid>
      </Container>

      {/**
       * Popup Tambah Data
       */}
      <ModalUpdateProfil user={user} open={openUpdate} onClose={handleCloseUpdate} onUpdateData={updateProfile} />
    </>
  );
}
