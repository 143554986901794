import { Outlet, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

ProtectedAccess.propTypes = {
  children: PropTypes.element,
  permissions: PropTypes.array,
  replacePage: PropTypes.bool,
};
export default function ProtectedAccess({ children, permissions, replacePage = false }) {
  /**
   ** Data variable
   */
  const userPermissions = JSON.parse(localStorage.getItem('permissions'));

  /**
   ** Check authorization
   */
  if (!permissions.some((permission) => userPermissions.includes(permission))) {
    return <Navigate to="/403" replace={replacePage} />;
  }

  return children || <Outlet />;
}
