import Proptypes from 'prop-types';
// @mui
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

ConfirmDelete.prototype = {
  open: Proptypes.bool.isRequired,
  isSubmit: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  onDeleteData: Proptypes.func.isRequired,
};

export default function ConfirmDelete({ open, isSubmit, onClose, onDeleteData }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Konfirmasi'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">Yakin untuk menghapus data ini?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        <Button disabled={isSubmit} onClick={onDeleteData}>
          Hapus
        </Button>
      </DialogActions>
    </Dialog>
  );
}
