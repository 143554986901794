import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
import { PulseLoader } from 'react-spinners';

LoadingCard.prototype = {
  isLoading: PropTypes.bool,
};

function LoadingCard({ isLoading }) {
  return (
    <Box textAlign={'center'} marginY={25}>
      <PulseLoader color={'#308fe8'} size={10} loading={isLoading} />
    </Box>
  );
}

export default LoadingCard;
