import axios from 'axios';

export default class GatewayHttpService {
  static getToken() {
    const authorization = JSON.parse(localStorage.getItem('authorization'));
    return authorization.token ?? null;
  }

  static secureRequest = async (method, url, data, others) => {
    let apiResponse = {};

    try {
      apiResponse = await axios({
        method,
        url,
        data,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${GatewayHttpService.getToken()}` },
        ...others,
      });
    } catch (error) {
      if (!error.response) {
        console.log(error.message);
      } else if (error.response.status === 401) {
        const { response } = error;
        apiResponse = response;
        apiResponse.data.data = [];

        localStorage.removeItem('authorization');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        sessionStorage.setItem('pesan', 'Sesi anda telah berakhir');
        window.location.href = '/login';
      } else {
        apiResponse = error.response;
      }
    }

    return apiResponse;
  };

  static secureRequestFormData = async (method, url, data) => {
    let apiResponse = {};

    const formData = new FormData();
    Object.entries(data).forEach((item) => {
      formData.append(item[0], item[1]);
    });

    try {
      apiResponse = await axios({
        method,
        url,
        data,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${GatewayHttpService.getToken()}` },
      });
    } catch (error) {
      if (!error.response) {
        console.log(error.message);
      } else if (error.response.status === 401) {
        const { response } = error;
        apiResponse = response;
        apiResponse.data.data = [];

        localStorage.removeItem('authorization');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        sessionStorage.setItem('pesan', 'Sesi anda telah berakhir');
        window.location.href = '/login';
      } else {
        apiResponse = error.response;
      }
    }

    return apiResponse;
  };

  static secureGet = async (url) => GatewayHttpService.secureRequest('get', url);

  static securePost = async (url, data) => GatewayHttpService.secureRequest('post', url, data);

  static securePut = async (url, data) => GatewayHttpService.secureRequest('put', url, data);

  static securePatch = async (url, data) => GatewayHttpService.secureRequest('patch', url, data);

  static secureDelete = async (url) => GatewayHttpService.secureRequest('delete', url);

  static secureGetFile = async (url) => GatewayHttpService.secureRequest('get', url, null, { responseType: 'blob' });

  static securePostFormData = async (url, data) => GatewayHttpService.secureRequestFormData('post', url, data);
}
