import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Box, Card, Container, Typography, Dialog } from '@mui/material';
// components
import TitlePage from '../../components/title-page';
import { LoadingCard } from '../../components/loading';
import { ItemData } from '../../components/item-data';
// sections
import {
  AssessmentDetailTable,
  CPLTable,
  CoursePlanAssessmentTable,
  CoursePlanLoTable,
  CoursePlanMaterialTable,
  CoursePlanMediaTable,
  CoursePlanReferenceTable,
  IndicatorTable,
  MappingTable,
} from '../../sections/rps';
import Menu from './Menu';
// services
import GatewayHttpService from '../../services/GatewayHttpService';

export default function RPSPage() {
  /**
   ** Component variable
   */
  const [isLoading, setIsLoading] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);

  /**
   ** Data variable
   */
  const { id } = useParams();
  const [data, setData] = useState({});
  const [groupBy, setGroupBy] = useState({
    cpl: {},
    indicator: {},
  });
  const [pdfUrl, setPdfUrl] = useState('');

  /**
   ** Handle component, sort data, and pagination
   */
  const handleClosePdf = () => {
    setOpenPdf(false);
    window.URL.revokeObjectURL(pdfUrl);
    setPdfUrl('');
  };

  /**
   ** Handle data
   */
  const groupCpmk = (mappings) => {
    setGroupBy(() => ({
      cpl: Object.groupBy(mappings, (lo) => lo.curriculum_plo_code),
      indicator: Object.groupBy(mappings, (lo) => lo.curriculum_indicator_code),
    }));
  };

  /**
   ** Handle calling API
   */
  const getData = async () => {
    const response = await GatewayHttpService.secureGet(`${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}`);

    if (response.data.data.mappings.length) {
      groupCpmk(response.data.data.mappings);
    }

    setData(response.data.data);
    setIsLoading(false);
  };

  const print = async () => {
    setIsPrint(true);
    const response = await GatewayHttpService.secureGetFile(
      `${process.env.REACT_APP_BACKEND_HOST}/api/course-plan/${id}/print`
    );

    setPdfUrl(window.URL.createObjectURL(response.data));
    setIsPrint(false);
    setOpenPdf(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Container>
        <TitlePage title="Rencana Pembelajaran Semester" />
        <Menu isPrint={isPrint} onPrint={print} />

        {isLoading ? (
          <LoadingCard isLoading={isLoading} />
        ) : (
          <Card sx={{ padding: 3 }}>
            {/**
             * Data Mata Kuliah
             */}
            <ItemData itemLabel="Kode" itemValue={data.code} sx={{ marginTop: 0 }} />
            <ItemData itemLabel="Nama" itemValue={data.name} />
            <ItemData itemLabel="Bobot" itemValue={`${data.credit} SKS`} />
            <ItemData itemLabel="Semester" itemValue={data.semester} />
            <ItemData itemLabel="Deskripsi" itemValue={data.description} />
            <ItemData itemLabel="Dosen" itemValue={data.lecturers} objectKey="id" objectValue="name" />
            <ItemData itemLabel="Prasyarat" itemValue={data.requirements} objectKey="id" objectValue="name" />

            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 1: CPL */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 1 : CPL Prodi</Typography>
            </Box>
            <CPLTable data={data.curriculum_plos} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 2: Indikator MK */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 2 : Indikator Mata Kuliah</Typography>
            </Box>
            <IndicatorTable data={data.curriculum_indicators} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 3: CPMK */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 3 : CPMK</Typography>
            </Box>
            <CoursePlanLoTable data={data.course_plan_los} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 4: Mapping CPL - Indikator - CPMK */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 4 : Pemetaan</Typography>
            </Box>
            <MappingTable data={data.mappings} groupBy={groupBy} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 5: Materi */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 5 : Materi</Typography>
            </Box>
            <CoursePlanMaterialTable data={data.course_plan_materials} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 6: Referensi */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 6 : Referensi</Typography>
            </Box>
            <CoursePlanReferenceTable data={data.course_plan_references} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 7: Media */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 7 : Media</Typography>
            </Box>
            <CoursePlanMediaTable data={data.course_plan_media} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 8: Assessment */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 8 : Assessment</Typography>
            </Box>
            <CoursePlanAssessmentTable data={data.course_plan_assessments} />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />

            {/* Bagian 9: Detail Assessment */}
            <Box mt={3} mb={2}>
              <Typography variant="subtitle1">Bagian 9 : Detail Assessment</Typography>
            </Box>
            <AssessmentDetailTable
              coursePlanLos={data.course_plan_los}
              coursePlanAssessments={data.course_plan_assessments}
            />
            <Box mt={5} sx={{ borderBottom: '1px solid', borderColor: 'text.disabled' }} />
          </Card>
        )}
      </Container>

      {/**
       * Popup viewer pdf
       */}
      <Dialog id="dialog-pdf" fullWidth maxWidth={'lg'} open={openPdf} onClose={handleClosePdf}>
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          title="Rencana Pembelajaran Semester (RPS).pdf"
          style={{ border: 'none' }}
        />
      </Dialog>
    </>
  );
}
